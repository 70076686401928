import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./style.scss";
const Breadcrum = ({ name, path }) => {
    const navigate = useNavigate();
    return (
        <div className='topBreadcrum'>
            <div><span onClick={() => navigate("/")} alt="Home">Home</span> / <span className='colored' onClick={() => navigate(path)} alt={name}>{name}</span></div>
        </div>
    )
}

export default Breadcrum;