export const locations = [
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Khichripur",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/PfqjWLinRvNs2fsJ7"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Civil Lines",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/v1GCf5er9yLAnToi8"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Shalimar Bagh",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/DYes8UcrQAezCwcq6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector 23-Rohini",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/BochTKkWP9jd2Z196"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Hari Nagar",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/DNmhS131DpoPWRL69"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector 10 Dwarka",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/co9M7epo5NjbyzJm8"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Kalkaji",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/YpEJKuvczxfZfEep9"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Link Road, Karol Bagh",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/6L2SiCNo2FvBYRMa9"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Gautampuri",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/vmEQRbuoG9xPfeYu5"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE INA Colony",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/PEu8sv8MQbiGFeJx7"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector 18 Rohini",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/mvnR3bL8po2uQrmh7"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector 6 Dwarka",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/YigLbfGN9vTfZU457"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Gandhi Nagar",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/dmhXUa16RsYrqh186"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Nai Basti, Kishanganj",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/6x8vZftQnqKACQkL9"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Libaspur ",
        "Type": "STEM",
        "Location": "https://goo.gl/maps/xczr7xFLKSGpybrs6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sec-21 Rohini",
        "Type": "PVA",
        "Location": "https://goo.gl/maps/pk8HrUYCYHFtmdzy6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sec-19 Dwarka",
        "Type": "PVA",
        "Location": "https://goo.gl/maps/wPYuLGiNsSGFZ8S26"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE I P Extension",
        "Type": "PVA",
        "Location": "https://goo.gl/maps/af6XGfskkhCrmKC49"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Andrewsganj",
        "Type": "PVA",
        "Location": "https://goo.gl/maps/YyVPgH66vnDgTZT27"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Gandhi Nagar",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/dmhXUa16RsYrqh186"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Nand Nagri",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/RwSkv1LkQ4yRNbms9"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector 17 Rohini",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/9Pju4SV3NxMqWX8PA"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sec-22 Dwarka",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/Ua96kig8mS4iEvzR7"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Madanpur Khadar",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/1zjDCDaNqxqFFPYE8"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Kishan Ganj",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/6x8vZftQnqKACQkL9"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Paschim Vihar",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/SeKem7WDWDnU3aK49"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Khichripur",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/PfqjWLinRvNs2fsJ7"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Libaspur ",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/xczr7xFLKSGpybrs6"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Kalkaji",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/YpEJKuvczxfZfEep9"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, DESU colony, Janakpuri ",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/fRcnc2iH8Vz9qPVNA"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Sarojini Nagar ",
        "Type": "Humanities",
        "Location": "https://goo.gl/maps/oQ97xMGqSVThi23R8"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Surajmal Vihar",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/AvNZ8AauXn6ZdZzr5"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Narela",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/XTqL6D54SuaN4HHD6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sec-11 Rohini",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/8c7d9K1mojsxRXmB6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Sector-5 Dwarka",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/dDQ74Bf83kEiJJYSA"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Lajpat Nagar",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/9ncxByLwEnNFj2ck7"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Raj Niwas Marg",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/NpWryP6rA2QAH6EX6"
    },
    {
        "SchoolName": "Dr. B. R. Ambedkar SOSE Yamuna Vihar",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/Krwb68W5nqcMbrWT8"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, DESU colony, Janakpuri ",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/fRcnc2iH8Vz9qPVNA"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Mehram Nagar ",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/DRTWwvCv1ute6ZT46"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Kohat Enclave ",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/qn3wPAHwTVU7Jz698"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Sector-18, Rohini",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/mvnR3bL8po2uQrmh7"
    },
    {
        "SchoolName": "Dr. B R Ambedkar SOSE, Madanpur Khada",
        "Type": "HE21",
        "Location": "https://goo.gl/maps/1zjDCDaNqxqFFPYE8"
    },
    {
        "SchoolName": "Shaheed Bhagat Singh AFPS, Jharoda Kalan",
        "Type": "AFPS",
        "Location": "https://goo.gl/maps/bYwdPEptFgppmnop9"
    }
];