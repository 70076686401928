import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./style.scss";

export default function SchoolGallery({ images }) {
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    return (
        <div className="specializationGallery" >
            <div className="specializationGallery_section" >
                <div className="specializationGallery_container" >
                    <Gallery
                        images={images.slice(0,5)}
                        onClick={handleClick}
                        enableImageSelection={false}
                    />
                </div>
            </div>
            {!!currentImage && (
                <Lightbox
                    mainSrc={currentImage.src}
                    mainSrcThumbnail={currentImage.src}
                    nextSrc={nextImage.src}
                    nextSrcThumbnail={nextImage.src}
                    prevSrc={prevImage.src}
                    prevSrcThumbnail={prevImage.src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                />
            )}
        </div>
    );
}