import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.scss";
import Banner from "../../assets/images/humanities-banner.png";
import { arrowDown, arrowUp } from '../../assets/images/svg';
import Humanities1 from '../../assets/images/why-humanities-1.png';
import Humanities2 from '../../assets/images/why-humanities-2.png';
import Humanities3 from '../../assets/images/why-humanities-3.png';
import Humanities4 from '../../assets/images/why-humanities-4.png';
import Humanities5 from '../../assets/images/why-humanities-5.png';
import HumanitiesStudentTestimonial from '../../assets/images/humanities_Student_Testimonial.png';
import HumanitiesTeacherTestimonial from '../../assets/images/Humanities_teacher_Testimonial.jpg';
import SpecilisationBorder from '../../assets/images/specilisation-border.png';
import SpecilisationBorder5 from '../../assets/images/specilisation-border-5.png';
import CareerPathways from '../../assets/images/career_pathways.png';
import TISSLogo from '../../assets/images/TISS_logo.png';
import AshokaLogo from '../../assets/images/Ashoka_Uni_logo.png';
import VasantValleyLogo from '../../assets/images/vasant_valley_logo.png';
import Breadcrum from '../../common/Breadcrum';
import SchoolGallery from '../../common/Gallery';
import GalleryImg1 from '../../assets/images/humanities-gallery-1.jpg';
import GalleryImg2 from '../../assets/images/humanities-gallery-2.jpg';
import GalleryImg3 from '../../assets/images/humanities-gallery-3.jpg';
import GalleryImg4 from '../../assets/images/humanities-gallery-4.jpg';
import GalleryImg5 from '../../assets/images/humanities-gallery-5.jpg';
import GalleryImg6 from '../../assets/images/humanities-gallery-6.jpg';
import GalleryImg7 from '../../assets/images/humanities-gallery-7.jpg';
import { LanguageContext } from '../../App';
import { lang } from '../../language';

const Humanities = () => {
    const language= React.useContext(LanguageContext)

    const [showCurriculum, setShowCurriculum] = useState(false);
    const [showCreators, setShowCreators] = useState(false);

    return (
        <div className='specialization'>
            <Breadcrum name={"Humanities"} path="/specialisation/humanities" />
            <div className='banner'>
                <img src={Banner} alt="DBSE SOSE" title='DBSE SOSE' />
                <div className="inner-breadcrum-container">
                    <div className="inner-breadcrum" dangerouslySetInnerHTML={{ __html: lang[language].Humanities.inner_breadcrum }}></div>
                    <div className="inner-breadcrum-hover" dangerouslySetInnerHTML={{ __html: lang[language].Humanities.inner_breadcrum_hover }}></div>
                </div>
            </div>
            <div className='specialization-description' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.specialization_description }}></div>
            <div className='specialization-section'>
                <div className='specialization-content'>

                    <div className='why'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.whytitle }}></div>
                        <div className='why-card'>
                            <img src={Humanities1} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].Humanities.why_card1 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities2} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].Humanities.why_card2 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities3} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].Humanities.why_card3 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities4} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].Humanities.why_card4 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities5} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].Humanities.why_card5 }}></div>
                            </div>
                        </div>
                    </div>

                    <div className='accordian currriculum'>
                        <div className='outer' onClick={() => setShowCurriculum(!showCurriculum)}>
                            {showCurriculum ? (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.title }}></div>    
                            ): (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.titleClosed }}></div>
                            )}
                            <div className='icon'>{showCurriculum ? arrowUp : arrowDown}</div>
                        </div>
                        {showCurriculum && (

                            <div className='inner'>
                                <div className='currriculum_design'>
                                    <div className='currriculum_grade_section'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.grade9_10 }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading left' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_subjects_left }}></div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading right' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_subjects_right }}></div>
                                            </div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description left'>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Mathematics }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.English }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Hindi }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Science }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Individual }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Entrepreneurial }}></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description right'>
                                                    <div className='paragraph'>
                                                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_description_right.title1 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_description_right.desc1 }}></div>
                                                    </div>
                                                    <div className='paragraph'>
                                                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_description_right.title2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_description_right.desc2 }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='currriculum_grade_section section_change'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.grade11_12 }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>
                                        <div className='currriculum_container'>
                                            <div className='currriculum_heading all' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.currriculum_subjects }}></div>
                                        </div>
                                        <div className='currriculum_container'>
                                            <div className='currriculum_description all'>
                                                <ul>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Social_Sciences }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.English1 }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Interdisciplinary }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.World_of_Work }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.Any_two }}></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className='horizontal'></div>
                    <div className='accordian'>
                        <div className='outer' onClick={() => setShowCreators(!showCreators)}>
                            <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Knowledge_Partners.title }}></div>
                            <div className='icon'>{showCreators ? arrowUp : arrowDown}</div>
                        </div>
                        {showCreators && (

                            <div className='inner'>

                                <div className='creators_container'>
                                    <div className='images_container'>
                                        <img src={VasantValleyLogo} alt="TISS" />
                                        <img src={TISSLogo} alt="TISS" />
                                        <img src={AshokaLogo} alt="Ashoka University" />
                                    </div>
                                    <div className='content_container'>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Knowledge_Partners.Vasant_Valley }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Knowledge_Partners.Institute_of_Social_Sciences }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Knowledge_Partners.Ashoka_University }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Knowledge_Partners.teachers }}></p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='career_pathways'>
                <img src={CareerPathways} alt="CareerPathways" />
            </div>

            <div className='elegibility_criteria_section'>
                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Eligibility.titile }}></div>

                <div className='elegibility_criteria_container'>
                    <div className='elegibility_criteria_container2'>
                        <p dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Eligibility.text }}></p>
                        {/* <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")} dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Eligibility.Apply_Now }}></button> */}
                    </div>
                    <div className='border'></div>
                </div>

                <div className='map-container'>
                    <iframe
                        src="https://www.google.com/maps/d/embed?mid=1-jfMJcBTtWxJvHdTpRsqOCdUD5QKur4&ehbc=2E312F"
                        width="100%"
                        height="100%"
                        title='List Of ASoSE Humanities Schools'
                    />
                </div>

                <div className='title testinomials_heading' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.Testimonials }}></div>
            </div>

            <div className='testimonials_section'>
                <Carousel
                    showArrows={true}
                    autoPlay
                    emulateTouch
                    swipeable
                    stopOnHover
                    infiniteLoop
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                >
                    <div className='testimonials_container'>
                        <div className='image'><img src={HumanitiesStudentTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.para }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.name }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.post }}></div>

                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={HumanitiesTeacherTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.para1 }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.name1 }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.post1 }}></div>
                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={TISSLogo} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.para2 }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.name2 }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.post2 }}></div>
                        </div>
                    </div>
                </Carousel>

            </div>

            <SchoolGallery images={[
                { src: GalleryImg1, width: 250, height: 152 },
                { src: GalleryImg2, width: 250, height: 152 },
                { src: GalleryImg3, width: 250, height: 152 },
                { src: GalleryImg4, width: 250, height: 152 },
                { src: GalleryImg5, width: 250, height: 152 },
                { src: GalleryImg6, width: 250, height: 152 },
                { src: GalleryImg7, width: 250, height: 152 }
            ]} />
        </div>
    )
}

export default Humanities;