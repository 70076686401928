/* eslint-disable jsx-a11y/no-distracting-elements */
import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Banner from "../assets/images/home-banner.png";
import Banner2 from "../assets/images/home-banner-2.png";
import DCM from "../assets/images/dcm.png";
import "./style.scss";
import { STEMIcon, HE21Icon, AFPSIcon, HumanitiesIcon, PVAIcon } from '../assets/images/svg';
import BCGLogo from "../assets/images/BCG_logo.png";
import ACERLogo from "../assets/images/ACER_logo.png";
import AshokaUniLogo from "../assets/images/Ashoka_Uni_logo.png";
import CampK12Logo from "../assets/images/Camp_K12.png";
import IBLogo from "../assets/images/IB_logo.png";
import GMILogo from "../assets/images/GMI_logo.png";
import IITDelhiLogo from "../assets/images/IIT_Delhi_logo.png";
import LAHILogo from "../assets/images/LAHI_logo.png";
import MAHELogo from "../assets/images/MAHE_logo.png";
import NIFTLogo from "../assets/images/NIFT_logo.png";
import TISSLogo from "../assets/images/TISS_logo.png";
import VMCLogo from "../assets/images/VMC_logo.png";
import ENFLogo from "../assets/images/ENF_Logo.png";
import VasantValleyLogo from "../assets/images/vasant_valley_logo.png";
import WhistlingWoodsLogo from "../assets/images/WhistlingWoodsLogo.png";
import NewItem from "../assets/images/new.jpg";
import NoticeWebinarsForSpecilizationPDF from "../assets/docs/webinars_for_specialisations.pdf";
import CircularGuidelinesPDF from "../assets/docs/Circular guidelines for admission 2023-24.pdf";
import LastDateOfApplicationPDF from "../assets/docs/Last_Date_of_Application.pdf";
import EntranceTestDateSheetPDF from "../assets/docs/ASoSE Entrance Test Date Sheet.pdf";
import { useNavigate } from 'react-router-dom';
import { lang } from '../language';
import { LanguageContext } from '../App';

const Home = () => {

  const language = React.useContext(LanguageContext)
  const navigate = useNavigate();

  return (
    <div className='home'>
      <div className='banner'>
        <Carousel
          showArrows={true}
          autoPlay
          emulateTouch
          swipeable
          stopOnHover
          infiniteLoop
          showStatus={false}
          showThumbs={false}
        >
          <img src={Banner} alt="DBSE SOSE" title='DBSE SOSE' />
          <img src={Banner2} alt="DBSE SOSE" title='DBSE SOSE' />
        </Carousel>
      </div>
      <div className='dcmMessage'>
        <div className='updates'>
          <div className='updateHeading'>{lang[language].home.updatesHeading}</div>
          <div className='marquee'>
            <marquee direction="left" dangerouslySetInnerHTML={{ __html: lang[language].home.updates }} ></marquee>
          </div>
        </div>
        <div className='messageContainer'>
          <div className='dcmPic'><img src={DCM} alt="DCM" title='Shri Manish Sisodia' /></div>
          <div className='message'>
            <div className='content' dangerouslySetInnerHTML={{ __html: lang[language].home.dcmMessage }}></div>
            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].home.dcmName }}></div>
            <div className='position' dangerouslySetInnerHTML={{ __html: lang[language].home.dcmPosition }}></div>

          </div>
        </div>
      </div>

      <div className='specialization'>
        <div className='specialization_container'>
          <div className='specialization_topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.heading }}></div>

          <div className='specialization_card' onClick={() => navigate("/specialisation/stem")}>
            <div className='icon'>{STEMIcon}</div>
            <div className='topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.stem }}></div>
            <div className='view-more' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.viewMore }}></div>
          </div>

          <div className='specialization_card' onClick={() => navigate("/specialisation/he21")}>
            <div className='icon'>{HE21Icon}</div>
            <div className='topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.he21 }}></div>
            <div className='view-more' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.viewMore }}></div>
          </div>

          <div className='specialization_card' onClick={() => navigate("/specialisation/afps")}>
            <div className='icon'>{AFPSIcon}</div>
            <div className='topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.afps }}></div>
            <div className='view-more' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.viewMore }}></div>
          </div>

          <div className='specialization_card' onClick={() => navigate("/specialisation/humanities")}>
            <div className='icon'>{HumanitiesIcon}</div>
            <div className='topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.humanities }}></div>
            <div className='view-more' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.viewMore }}></div>
          </div>

          <div className='specialization_card' onClick={() => navigate("/specialisation/pva")}>
            <div className='icon'>{PVAIcon}</div>
            <div className='topic' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.pva }}></div>
            <div className='view-more' dangerouslySetInnerHTML={{ __html: lang[language].home.specialization.viewMore }}></div>
          </div>

        </div>
      </div>

      <div className='notices'>
        <div className='notices_heading' dangerouslySetInnerHTML={{ __html: lang[language].home.notices.heading }}></div>
        <div className='notices_container'>
          <ul>
            <li style={{ display: "flex", alignItems: "center" }}>
              <img src={NewItem} alt="" style={{ width: "60px", height: "40px" }} />
              <a href="https://www.edudel.nic.in//sose/admission/Home.aspx" target="_blank" rel='noreferrer'>All candidates who have qualified for <b>High End 21st Century Skills (STAGE 2 (Interview))</b> can now download their Round 2 Admit Card from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.</a>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <img src={NewItem} alt="" style={{ width: "60px", height: "40px" }} />
              <a href="https://www.edudel.nic.in//sose/admission/Home.aspx" target="_blank" rel='noreferrer'>All candidates who have qualified for <b>Humanities ASoSE Test (Round 2)</b> can now download their Round 2 Admit Card from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.</a>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <img src={NewItem} alt="" style={{ width: "60px", height: "40px" }} />
              All candidates who have qualified for Performing and Visual Arts ASoSE Test (Round 2) can now download their Round 2 Admit Card(s) from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.
            </li>
            <li>
              <div style={{ display: "flex", alignItems: "center" }}>
                Admit Card for ASoSE Admission Test - 2023 are now available for Sepcialisation(s) :
              </div>
              <ul style={{ overflow: "none" }}>
                <li><b>1. Science, Technology, Engineering and Mathematics (STEM) (Class - IX)</b></li>
                <li><b>2. Performing and Visual Arts (Class - IX)</b></li>
                <li><b>3. High-end 21st Century Skills (Class - IX)</b> </li>
                <li><b>4. Humanities (Class - IX)</b> </li>
                <li><b>5. Shaheed Bhagat Singh - Armed Forces Preparatory School (Class - IX)</b> </li>
              </ul>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}><a href={EntranceTestDateSheetPDF} target="_blank" rel='noreferrer'>Aptitude test for admissions to Dr. B.R. Ambedkar Schools of Specialised Excellence (ASoSEs) for the academic session 2023-24.</a></li>
            <li>Registrations for ASOSE Admissions for Class 9 in  all specialisations for Academic Year 2023-24 are closed now.</li>
            <li><a href={LastDateOfApplicationPDF} target="_blank" rel='noreferrer'>Online Applications for admissions in Dr. B. R. Ambedkar Schools of Specialised Excellence are now open till <b> 17th January 2023</b>.</a></li>
            <li><a href='https://www.edudel.nic.in//sose/admission/Home.aspx' target="_blank" rel='noreferrer'>Online correction window is open from <b>12th January 2023 to 17th January 2023</b>. The registered applicants may edit/make changes in the application form, if required.</a></li>
            <li><a href={CircularGuidelinesPDF} target="_blank" rel='noreferrer'>Admissions Circular 2023-24</a></li>
            <li><a href="https://youtu.be/SZiMWwfKHR0" target="_blank" rel='noreferrer' ><b>Instructions for filling the ASOSE Application Form &nbsp; Click here to watch the video</b></a></li>
            <li><a href="https://bit.ly/ASoSE-AFPS" target="_blank" rel='noreferrer'>Webinar on Shaheed Bhagat Singh Armed Forces Preparatory School (AFPS) is on January 7th 2023, 7-8 pm.</a></li>
            <li><a href="https://bit.ly/ASoSE-STEM" target="_blank" rel='noreferrer'>Webinar on Science, Technology, Engineering & Mathematics (STEM) ASoSE is on January 6th 2023, 7-8 pm.</a></li>
            <li><a href="https://bit.ly/ASoSE-Humanities" target="_blank" rel='noreferrer'>Webinar on Humanities ASoSE is on January 5th 2023, 7-8 pm.</a></li>
            <li><a href="https://bit.ly/ASoSE-PVA" target="_blank" rel='noreferrer'>Webinar on Performing and Visual Arts (PVA) ASoSE is on January 4th 2023, 7-8 pm.</a></li>
            <li><a href="https://bit.ly/ASoSE-HE21" target="_blank" rel='noreferrer'>Webinar on High End 21st Century Skills (HE-21) ASosE is on January 3rd 2023, 7-8 pm.</a></li>
            <li><a href={NoticeWebinarsForSpecilizationPDF} target="_blank" rel='noreferrer'>Webinars For Specialisations</a></li>
            <li onClick={() => navigate("/admissions")}>Syllabus for all specialisation test are now available on the "Admissions" Page.</li>
            <li onClick={() => navigate("/about/sose")}>List of all DBRA SOSEs are now available on the "About ASOSE" Page</li>
            <li><a href='https://www.edudel.nic.in//sose/admission/Home.aspx'>Admissions for Class 9 in all specialisations for Academic Year 2023-24 are now open.</a></li>
          </ul>
        </div>
        <div className='notices_heading' dangerouslySetInnerHTML={{ __html: lang[language].home.partners }}></div>
      </div>

      <div className='partners'>
        <marquee
          scrollamount={10}
          behavior="scroll"
        >
          <div className='logos'>
            <img src={BCGLogo} alt="BCG" />
            <img src={ACERLogo} alt="ACER" />
            <img src={IBLogo} alt="Camp K12" />
            <img src={AshokaUniLogo} alt="Ashoka Uni" />
            <img src={CampK12Logo} alt="Camp K12" />
            <img src={GMILogo} alt="GMI" />
            <img src={VasantValleyLogo} alt="Vasant Valley" />
            <img src={ENFLogo} alt="Vasant Valley" />
          </div>

          <div className='logos'>
            <img src={IITDelhiLogo} alt="IIT Delhi" />
            <img src={LAHILogo} alt="LAHI" />
            <img src={MAHELogo} alt="MAHE" />
            <img src={NIFTLogo} alt="NIFT" />
            <img src={WhistlingWoodsLogo} alt="Whistling Woods" />
            <img src={TISSLogo} alt="TISS" />
            <img src={VMCLogo} alt="VMC" />
          </div>
        </marquee>
      </div>

      <div className='notices'></div>

    </div>
  )
}

export default Home