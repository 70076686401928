import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.scss";
import Banner from "../../assets/images/he21-banner.png";
import { arrowDown, arrowUp } from '../../assets/images/svg';

import HE211 from '../../assets/images/why-he21-1.png';
import HE212 from '../../assets/images/why-he21-2.png';
import HE213 from '../../assets/images/why-he21-3.png';
import HE214 from '../../assets/images/why-he21-4.png';

import He21PartnerTestimonial from '../../assets/images/he21_partner_Testimonial.png';
import He21StudentTestimonial from '../../assets/images/he21_student_Testimonial.png';
import He21TeacherTestimonial from '../../assets/images/he21_teacher_Testimonial.png';

import SpecilisationBorder from '../../assets/images/specilisation-border.png';
import SpecilisationBorder5 from '../../assets/images/specilisation-border-5.png';
import CareerPathways from '../../assets/images/career_pathways_he21.png';

import IITDelhiLogo from '../../assets/images/IIT_Delhi_logo.png';
import NIFTLogo from '../../assets/images/NIFT_logo.png';
import CampK12Logo from '../../assets/images/Camp_K12.png';
import LAHILogo from '../../assets/images/LAHI_logo.png';

import GalleryImg1 from '../../assets/images/he21-gallery-1.jpg';
import GalleryImg2 from '../../assets/images/he21-gallery-2.jpg';
import GalleryImg3 from '../../assets/images/he21-gallery-3.jpg';
import GalleryImg4 from '../../assets/images/he21-gallery-4.jpg';
import GalleryImg5 from '../../assets/images/he21-gallery-5.jpg';

import Breadcrum from '../../common/Breadcrum';
import SchoolGallery from "../../common/Gallery";
import { LanguageContext } from "../../App";
import { lang } from "../../language";

const HE21 = () => {
  const language= React.useContext(LanguageContext)

  const [showCurriculum, setShowCurriculum] = useState(false);
  const [showCreators, setShowCreators] = useState(false);

  return (
    <div className="specialization">
      <Breadcrum name={"High-End 21st Century Skills"} path="/specialisation/he21" />
      <div className="banner">
        <img src={Banner} alt="DBSE SOSE" title="DBSE SOSE" />

        <div className="inner-breadcrum-container">
          <div className="inner-breadcrum" dangerouslySetInnerHTML={{ __html: lang[language].he21.inner }}></div>
          <div className="inner-breadcrum-hover" dangerouslySetInnerHTML={{ __html: lang[language].he21.breadcrumHover }}></div>
        </div>
      </div>
      <div className="specialization-description" dangerouslySetInnerHTML={{ __html: lang[language].he21.specializationdescription }}></div>
      <div className="specialization-section">
        <div className="specialization-content">
          <div className='why afps'>
            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.title }}></div>
            <div className='cards' >
              <div className="why-card">
                <img src={HE211} alt="DBSE SOSE" className="why-icon" />
                <div className="content">
                  <img src={SpecilisationBorder} alt="" />
                  <div dangerouslySetInnerHTML={{ __html: lang[language].he21.Card1 }}></div>
                </div>
              </div>
              <div className="why-card">
                <img src={HE212} alt="DBSE SOSE" className="why-icon" />
                <div className="content">
                  <img src={SpecilisationBorder5} alt="" />
                  <div dangerouslySetInnerHTML={{ __html: lang[language].he21.Card2 }}></div>
                </div>
              </div>
              <div className="why-card">
                <img src={HE213} alt="DBSE SOSE" className="why-icon" />
                <div className="content">
                  <img src={SpecilisationBorder} alt="" />
                  <div dangerouslySetInnerHTML={{ __html: lang[language].he21.Card3 }}></div>
                </div>
              </div>
              <div className="why-card">
                <img src={HE214} alt="DBSE SOSE" className="why-icon" />
                <div className="content">
                  <img src={SpecilisationBorder} alt="" />
                  <div dangerouslySetInnerHTML={{ __html: lang[language].he21.Card4 }}></div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordian currriculum">
            <div
              className="outer"
              onClick={() => setShowCurriculum(!showCurriculum)}
            >
              {/* <div className="title">Curriculum {showCurriculum && <br />} <span>Design</span></div> */}
              {showCurriculum ? (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title2 }}></div>    
                            ): (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title2Closed }}></div>
                            )}
              <div className="icon">{showCurriculum ? arrowUp : arrowDown}</div>
            </div>

            {showCurriculum && (
              <div className="inner">
                <div className="currriculum_design">
                  <div className="currriculum_grade_section">
                    <div className="heading">
                      <div className="horizontal-border"></div>
                      <div className="grade" dangerouslySetInnerHTML={{ __html: lang[language].he21.grade }}></div>
                      <div className="horizontal-border"></div>
                    </div>

                    <div className="currriculum_subjects">
                      <div className="currriculum_container">
                        <div className="currriculum_heading left" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_heading_left }}>
                        </div>
                      </div>
                      <div className="currriculum_container">
                        <div className="currriculum_heading right" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_heading_right }}>
                        </div>
                      </div>
                    </div>

                    <div className="currriculum_subjects">
                      <div className="currriculum_container">
                        <div className="currriculum_description left">
                          <ul>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Mathematics }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.English }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Hindi }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Science }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.IndividualSocieties }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Entrepreneurial }}></li>
                          </ul>
                        </div>
                      </div>
                      <div className="currriculum_container">
                        <div className="currriculum_description right">
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.title3 }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc1 }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title2 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc2 }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title3 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc3 }}></div></div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.title4 }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title4a }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc4a }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title4b }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc4b }}></div>
                            <div className="sub-title" dangerouslySetInnerHTML={{ __html: lang[language].he21.sub_title4c }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.desc4c }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="currriculum_grade_section section_change">
                    <div className="heading">
                      <div className="horizontal-border"></div>
                      <div className="grade" dangerouslySetInnerHTML={{ __html: lang[language].he21.grade11_12 }}></div>
                      <div className="horizontal-border"></div>
                    </div>

                    <div className="currriculum_subjects">
                      <div className="currriculum_container">
                        <div className="currriculum_heading left" dangerouslySetInnerHTML={{ __html: lang[language].he21.Foundational_Subjects }}>
                        </div>
                      </div>
                      <div className="currriculum_container">
                        <div className="currriculum_heading right" dangerouslySetInnerHTML={{ __html: lang[language].he21.Specialised_Subjects }}></div>
                      </div>
                    </div>
                    <div className="currriculum_subjects">
                      <div className="currriculum_container">
                        <div className="currriculum_description left">
                          <ul>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Mathematics1 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.English1 }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Physics }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Chemistry }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Entrepreneurship }}></li>
                            <li dangerouslySetInnerHTML={{ __html: lang[language].he21.Economics }}></li>
                          </ul>
                        </div>
                      </div>
                      <div className="currriculum_container">
                        <div className="currriculum_description right">
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_container.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_container.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_container.desc2 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.currriculum_container.desc3 }}></div>
                          </div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph1.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph1.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph1.desc2 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph1.desc3 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph1.desc4 }}></div>
                          </div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph2.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph2.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph2.desc2 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph2.desc3 }}></div>
                          </div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph3.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph3.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph3.desc2 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph3.desc3 }}></div>
                          </div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph4.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph4.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph4.desc2 }}></div>
                          </div>
                          <div className="paragraph">
                            <div className="title" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph5.title }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph5.desc1 }}></div>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: lang[language].he21.paragraph5.desc2 }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='horizontal'></div>
          <div className="accordian">
            <div
              className="outer"
              onClick={() => setShowCreators(!showCreators)}
            >
              <div className='title'>Knowledge <span>Partners</span></div>
              <div className="icon">{showCreators ? arrowUp : arrowDown}</div>
            </div>
            {showCreators && (
              <div className="inner">
                <div className="creators_container">
                  <div className="images_container">
                    <img src={IITDelhiLogo} alt="IIT Delhi Logo" />
                    <img src={NIFTLogo} alt="NIFT Logo" />
                    <img src={CampK12Logo} alt="Camp K12 Logo" />
                    <img src={LAHILogo} alt="Lend A Hand Logo" />
                  </div>
                  <div className="content_container">
                    <p>
                      <strong>IIT Delhi: </strong>Indian Institute of Technology, Delhi is one of the Twenty Three IITs created to be Centres of Excellence for training, research and development in science, engineering and technology in India.
                      <br />
                      <a href="https://home.iitd.ac.in/">https://home.iitd.ac.in/</a>
                    </p>
                    <p>
                      <strong>NIFT Delhi: </strong>NIFT is the pioneering institute of fashion education in the country and has been in the vanguard of providing professional human resources to the textile and apparel industry. NIFT has also been working as a knowledge service provider to the Union and State governments in the area of design development and positioning of handlooms and handicrafts.
                      <br />
                      <a href="https://www.nift.ac.in/delhi">https://www.nift.ac.in/delhi</a>
                    </p>
                    <p>
                      <strong>Camp K12: </strong>Camp K12 is an Ed-tech startup founded by an MIT and Harvard computer science graduate and ex-CEO of Apple India to bring coding and 21st-century skills to students from age groups 6-18 around the globe. CampK12 is India's first coding boot camp for school kids. They are leading the K-12 education space, both offline and online, by making cutting-edge techs like AI, 3D/virtual reality programming, and web & mobile app development and making it accessible to young students via our custom-built technology platforms.
                      <br />
                      <a href="https://campk12.com/in">https://campk12.com/in</a>
                    </p>
                    <p>
                      <strong>Lend-a-Hand India: </strong>Lend-a-hand India is a non-profit organisation which aims to integrate skill education with mainstream education and prepare youth for employment and entrepreneurship opportunities by working at the intersection of education and livelihood.
                      <br />
                      <a href=" https://lendahandindia.org ">https://lendahandindia.org</a>
                    </p>
                    <p><i>They are trained by our marquee knowledge partners to deliver high quality classroom experiences based on the new-age curriculum.</i></p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="career_pathways">
        <img src={CareerPathways} alt="CareerPathways" />
      </div>

      <div className="elegibility_criteria_section">
        <div className="title">
          Eligibility <br />
          <span>Criteria</span>
        </div>

        <div className="elegibility_criteria_container">
          <div className="elegibility_criteria_container2">
            <p>
              Admissions to Ambedkar SOSE in HE 21 is open to students for Class 9. Students of Delhi (with valid residence proof) studying in Government, Government Aided or Recognised Schools of Delhi are eligible to apply for admission. Students must produce valid residence proof of Delhi at the time of admission. At least 50% students admitted to Schools of Specialized Excellence will be from Government or Government Aided Schools. Admission will be determined on the basis of a selection test. After qualifying the aptitude tests, students will have to produce a pass certificate of their respective previous grade (grade 8 for admission in grade 9 during session 2023-24). Please check the admissions page for more information regarding the selection test.
            </p>
            {/* <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")}>Apply Now</button> */}
          </div>
          <div className="border"></div>
        </div>

        <div className='map-container'>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1uFmvlgTqhkI2vLZ6Yrf_1Xw1KI0nMfI&ehbc=2E312F"
            width="100%"
            height="100%"
            title='List Of ASoSE HE 21 Schools'
          />
        </div>

        <div className="title testinomials_heading">Testimonials</div>
      </div>

      <div className="testimonials_section">
        <Carousel
          showArrows={true}
          autoPlay
          emulateTouch
          swipeable
          stopOnHover
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
        >
          <div className="testimonials_container">
            <div className="image">
              <img src={He21StudentTestimonial} alt="" />
            </div>
            <div className="desc">
              <div className="para">
                “Taking admission in these schools gave me an opportunity to
                learn many useful skills, which will help me make an informed
                decision about which career path to pursue. I learned coding
                here, and I would like to pursue a career in the same field
                which will secure my future."
              </div>
              <div className="name">Student</div>
              <div className="post">ASOSE- HE 21 </div>
            </div>
          </div>
          <div className="testimonials_container">
            <div className="image">
              <img src={He21TeacherTestimonial} alt="" />
            </div>
            <div className="desc">
              <div className="para">
                “What an amazing experience I had with these super creative and
                talented students! From an amazingly supporting school staff and
                incredible students to the availability of well-equipped skill
                labs with the latest and industrial standard technology Ambedkar
                SOSE has everything. And this has helped me in delivering
                practical knowledge of fashion and textile industry which is
                prevalent in the current business arena and prospects.”
              </div>
              <div className="name">Facilitator</div>
              <div className="post">
                Fashion and Aesthetics Facilitator, ASOSE- HE 21
              </div>
            </div>
          </div>
          <div className="testimonials_container">
            <div className="image">
              <img src={He21PartnerTestimonial} alt="" />
            </div>
            <div className="desc">
              <div className="para">
                Ambedkar SOSE is a great initiative. It is preparing children
                for the 21st century skills. Greater focus on early skill
                development will be helpful in career development for children
                who are sure which direction to take in their lives. Digital
                Media and Design has given a great opportunity for me to
                interact with very talented children in the Ambedkar SOSEs. Our
                class atmosphere is very vibrant and there is plenary of
                opportunity for creative people to participate in solution
                finding.
              </div>
              <div className="name">Prof. Jyoti Kumar</div>
              <div className="post"> Department of Design, IIT Delhi</div>
            </div>
          </div>
        </Carousel>
      </div>

      <SchoolGallery images={[
        { src: GalleryImg1, width: 250, height: 152 },
        { src: GalleryImg3, width: 250, height: 152 },
        { src: GalleryImg4, width: 250, height: 152 },
        { src: GalleryImg2, width: 250, height: 152 },
        { src: GalleryImg5, width: 250, height: 152 },
      ]} />

    </div>
  );
};

export default HE21;
