import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.scss";
import Banner from "../../assets/images/stem-banner.png";
import { arrowDown, arrowUp } from '../../assets/images/svg';
import Stem1 from '../../assets/images/why-stem-1.png';
import Stem2 from '../../assets/images/why-stem-2.png';
import Stem3 from '../../assets/images/why-stem-3.png';
import Stem4 from '../../assets/images/why-stem-4.png';
import Stem5 from '../../assets/images/why-stem-5.png';


import StemStudentTestimonial from '../../assets/images/stem_student_testimonial.png';
import StemTeacherTestimonial from '../../assets/images/Stem_teacher_testimonial.jpeg';
import StemPartnerTestimonial from '../../assets/images/stem_partner_testimonial.jpeg';

import SpecilisationBorder2 from '../../assets/images/specilisation-border-2.png';
import SpecilisationBorder5 from '../../assets/images/specilisation-border-5.png';
import CareerPathways from '../../assets/images/career_pathways_stem.png';
import VMCLogo from '../../assets/images/VMC_logo.png';
import Breadcrum from '../../common/Breadcrum';
import SchoolGallery from '../../common/Gallery';
import GalleryImg1 from '../../assets/images/stem-gallery-1.jpg'
import GalleryImg2 from '../../assets/images/stem-gallery-2.jpg'
import GalleryImg3 from '../../assets/images/stem-gallery-3.jpg'
import GalleryImg4 from '../../assets/images/stem-gallery-4.jpg'
import GalleryImg5 from '../../assets/images/stem-gallery-5.jpg'
import GalleryImg6 from '../../assets/images/stem-gallery-6.jpg'
import GalleryImg7 from '../../assets/images/stem-gallery-7.jpg'
import GalleryImg8 from '../../assets/images/stem-gallery-8.jpg'

const STEM = () => {

    const [showCurriculum, setShowCurriculum] = useState(false);
    const [showCreators, setShowCreators] = useState(false);

    return (
        <div className='specialization'>
            <Breadcrum name="Science, Technology, Engineering and Mathematics" path="/specialisation/stem" />
            <div className='banner'>
                <img src={Banner} alt="DBSE SOSE" title='DBSE SOSE' />
                <div className="inner-breadcrum-container">
                    <div className="inner-breadcrum">STEM</div>
                    <div className="inner-breadcrum-hover">Science, Technology, Engineering and Mathematics</div>
                </div>
            </div>
            <div className='specialization-description'>
                STEM Ambedkar SOSEs provide new age education in science disciplines to students who aspire to get into leading Indian and global institutes for engineering, medical, pure and applied sciences.The schools have partnered with leading institutions and focus on teaching STEM-focused curriculum to help students prepare for competitive exams in engineering (JEE), medicine (NEET), pure Sciences (CUET) etc.
            </div>
            <div className='specialization-section'>
                <div className='specialization-content'>

                    <div className='why'>
                        <div className='title'>Why <br /><span>STEM<br /> ASoSE?</span></div>
                        <div className='why-card'>
                            <img src={Stem1} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div>Extensive competitive exam preparation every day</div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Stem2} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder2} alt="" />
                                <div>Best-in-class STEM teachers</div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Stem3} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div>Data-backed performance analysis & mentorship</div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Stem4} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder2} alt="" />
                                <div>Monthly mock assessments</div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Stem5} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder2} alt="" />
                                <div>Exposure to state-of-art labs</div>
                            </div>
                        </div>
                    </div>

                    <div className='accordian currriculum'>
                        <div className='outer' onClick={() => setShowCurriculum(!showCurriculum)}>
                            <div className='title'>Curriculum {showCurriculum && <br />} <span>Design</span></div>
                            <div className='icon'>{showCurriculum ? arrowUp : arrowDown}</div>
                        </div>
                        {showCurriculum && (

                            <div className='inner'>
                                <div className='currriculum_design'>
                                    <div className='currriculum_grade_section'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade'>Grade 9 & 10</div>
                                            <div className='horizontal-border'></div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading left'>Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i></div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading right'>Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i></div>
                                            </div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description left'>
                                                    <ul>
                                                        <li>Mathematics</li>
                                                        <li>English</li>
                                                        <li>Hindi</li>
                                                        <li>Science</li>
                                                        <li>Individual & Societies</li>
                                                        <li>Entrepreneurial Mindset and Digital Design</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description right'>
                                                    <div className='paragraph'>
                                                        <div className='title'>Advanced Science</div>
                                                        <div className='desc'>In-depth study of science that enables students to apply scientific concepts in known and unknown questions asked during various competitive examinations.</div>
                                                    </div>
                                                    <div className='paragraph'>
                                                        <div className='title'>Advanced Mathematics</div>
                                                        <div className='desc'>Deeper understanding mathematical concepts aimed at enabling students to develop analytical ability and logical reasoning.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='currriculum_grade_section section_change'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade'>Grade 11 & 12</div>
                                            <div className='horizontal-border'></div>
                                        </div>


                                        <div className='currriculum_container'>
                                            <div className='currriculum_heading all'>Foundational Courses</div>
                                        </div>
                                        <div className='currriculum_container'>
                                            <div className='currriculum_description all'>
                                                <ul>
                                                    <li>Physics</li>
                                                    <li>Chemistry</li>
                                                    <li>Biology</li>
                                                    <li>Mathematics </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className='horizontal'></div>
                    <div className='accordian'>
                        <div className='outer' onClick={() => setShowCreators(!showCreators)}>
                            <div className='title'>Knowledge <span>Partners</span></div>
                            <div className='icon'>{showCreators ? arrowUp : arrowDown}</div>
                        </div>
                        {showCreators && (

                            <div className='inner'>

                                <div className='creators_container'>
                                    <div className='images_container'>
                                        <img src={VMCLogo} alt="Vidyamandir Classes" />
                                    </div>
                                    <div className='content_container'>
                                        <p><strong>Vidyamandir Classes(VMC): </strong>VMC is one of India's leading organisations which provide training and coaching to the students for competitive examinations such as JEE, NEET, CUET etc. VMC has a time-tested learning pedagogy, in which the students learn from the best teachers and scientifically designed study material. VMC also has a high success rate and provides personal mentorship to each student.
                                            <br />
                                            The teachers of STEM Ambedkar SOSEs, are renowned teachers working with Vidyamandir Classes, with a track record of teaching and guiding the students to crack JEE and NEET.
                                            <br />
                                            <a href="https://www.vidyamandir.com/" >https://www.vidyamandir.com/</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='career_pathways'>
                <img src={CareerPathways} alt="CareerPathways" />
            </div>

            <div className='elegibility_criteria_section'>
                <div className='title'>Eligibility <br /><span>Criteria</span></div>

                <div className='elegibility_criteria_container'>
                    <div className='elegibility_criteria_container2'>
                        <p>Admissions to Ambedkar SOSE-STEM is open to students for Class 9. Students of Delhi (with valid residence proof) studying in Government, Government Aided or Recognised Schools of Delhi are eligible to apply for admission. Students must produce valid residence proof of Delhi at the time of admission. At least 50% students admitted to Schools of Specialized Excellence will be from Government or Government Aided Schools. Admission will be determined on the basis of a selection test. After qualifying the aptitude test, students will have to produce a pass certificate of their respective previous grade (grade 8 for admission in grade 9 during session 2023-24). Please check the admissions page for more information regarding the selection test.</p>
                        {/* <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")}>Apply Now</button> */}
                    </div>
                    <div className='border'></div>
                </div>

                <div className='map-container'>
                    <iframe
                        src="https://www.google.com/maps/d/embed?mid=1T2xsVF_iVSJoO3dNgycAss6xT0kDi1k&ehbc=2E312F"
                        width="100%"
                        height="100%"
                        title='List Of ASoSE STEM Schools'
                    />
                </div>

                <div className='title testinomials_heading'>Testimonials</div>
            </div>

            <div className='testimonials_section'>
                <Carousel
                    showArrows={true}
                    autoPlay
                    emulateTouch
                    swipeable
                    stopOnHover
                    infiniteLoop
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                >
                    <div className='testimonials_container'>
                        <div className='image'><img src={StemStudentTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para'>“I joined Ambedkar SOSE because I wanted to pursue a career in STEM, and this institution gave me the chance to do so. This is possible because they partner with VMC and offer the DBSE board, which allows us to prepare for the competitive examinations alongside board preparations. The learning environment at Ambedkar SOSE is special in two ways: it's interactive, enjoyable, and conceptual, and the second factor is that other pupils are also goal-oriented, creating competition inside our school.”</div>
                            <div className='name'>Student</div>
                            <div className='post'>Class 9th, ASOSE - STEM</div>

                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={StemTeacherTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para'>"Following the inquiry based approach, class transactions are always two-way where students are encouraged to participate most of the time. Materials, apparatus, and instruments are made available in small groups, and hands-on experience to conduct various activities/experiments is provided to the students which were not possible in earlier schools.”</div>
                            <div className='name'>Facilitator</div>
                            <div className='post'>Science, ASOSE - STEM</div>
                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={StemPartnerTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para'>“Students get exclusive classes spearheaded by India’s best faculty at all times. They also get highly researched study materials comprehensively prepared through years of experience. During the course of the program, students have access to doubt clarifying sessions, performance tracking and VMC’s teaching methodology which is the best in its league. We have made the technology available to all so that everyone can learn effectively and do well on competitive exams like the JEE Main, JEE Advanced, the NEET, and other scholastic examinations like NTSE, INSPIRE (formerly KVPY), Boards, Olympiads and CUET.”</div>
                            <div className='name'>Saurabh</div>
                            <div className='post'>Academic Head, Vidyamandir Classes</div>
                        </div>
                    </div>
                </Carousel>

            </div>
            <SchoolGallery images={[
                { src: GalleryImg7, width: 250, height: 152 },
                { src: GalleryImg6, width: 250, height: 152 },
                { src: GalleryImg1, width: 250, height: 152 },
                { src: GalleryImg2, width: 250, height: 152 },
                { src: GalleryImg3, width: 250, height: 152 },
                { src: GalleryImg4, width: 250, height: 152 },
                { src: GalleryImg5, width: 250, height: 152 },
                { src: GalleryImg8, width: 250, height: 152 }
            ]} />
        </div>
    )
}

export default STEM;