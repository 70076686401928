import React, { useState } from 'react';
import "./admission.scss";
import AdmissionTimeline from "../assets/images/admission_timeline.png";
import afpsSyllabus from '../assets/docs/ASoSE_Aptitude Test_AFPS.pdf';
import he21Syllabus from '../assets/docs/ASoSE_Aptitude Test _HE-21.pdf';
import humanitiesSyllabus from '../assets/docs/ASoSE_ Aptitude Test_Humanities.pdf';
import pvaSyllabus from '../assets/docs/ASoSE_ Aptitude Test_Performing and Visual Arts.pdf';
import stemSyllabus from '../assets/docs/ASoSE_Aptitude Test_STEM.pdf';


const Admissions = () => {

    const [activeTab, setActiveTab] = useState("HE 21");

    return (
        <div className='admissions_section'>
            <div className='admissions_banner'>
                <div className="heading">Admissions</div>
                <div className="banner_container">
                    <button onClick={() => setActiveTab("HE 21")} className={activeTab === "HE 21" ? 'active' : ""} >HE 21</button>
                    <button onClick={() => setActiveTab("Humanities")} className={activeTab === "Humanities" ? 'active' : ""} >Humanities</button>
                    <button onClick={() => setActiveTab("PVA")} className={activeTab === "PVA" ? 'active' : ""} >PVA</button>
                    <button onClick={() => setActiveTab("STEM")} className={activeTab === "STEM" ? 'active' : ""} >STEM</button>
                    <button onClick={() => setActiveTab("AFPS")} className={activeTab === "AFPS" ? 'active' : ""} >AFPS</button>
                </div>

                <div className='active_heading'>{activeTab}</div>
            </div>
            <div className='admission_content'>
                <div className='admission_content_container'>
                    <div className='heading'>Residence &amp; previous school</div>
                    <div className='para'>Ambedkar SOSE admissions are open for students who are residents of NCT of Delhi (with a valid address proof) and are studying in a recognized school of NCT of Delhi. Both the residence and school conditions need to be met to be eligible for admissions in Ambedkar SOSEs.</div>
                    <br />
                    <div className='heading'>Age limit</div>

                    <div className='para'>For admission in Grade the applicant must have completed the age of 13 years but less than 15 years as of 31/03/2023.</div>
                    <div className='para'>Regular students currently enrolled in class 8th (academic session 22-23) in any recognised school of Delhi are eligible to apply irrespective of age.</div>
                    <div className='para'>Age relaxation is applicable as per Directorate of Education, government of NCT of Delhi norms.</div>
                    <br />
                    <div className='para'>At least 50% of the students admitted to ASoSE will be from Government or government-aided schools.</div>
                    <br />
                    <div className='heading'>Admission Process</div>

                    {activeTab === "HE 21" && (<div className='para'>Students will be selected through a two-stage aptitude assessment. The first stage will be a written test including verbal reasoning, numerical reasoning, and logical reasoning. Students selected for the second stage will be required to complete a project (project instructions will be communicated upon promotion to stage 2), and give an interview based on their submission.</div>)}
                    {activeTab === "Humanities" && (<div className='para'>Students will be selected through a two-stage aptitude assessment. The first stage will be a written test including verbal reasoning, numerical reasoning, and logical reasoning. Students selected for the second stage will be required to participate in  a group discussion.</div>)}
                    {activeTab === "STEM" && (<div className='para'>Students will be selected through an aptitude test that covers mental ability, mathematics, and science. For class 9 admission, topics covered may be of class 8 level.</div>)}
                    {activeTab === "PVA" && (<div className='para'>Students will be selected through a two part assessment. The first part will be taken by all students (regardless of chosen art-form) and will consist of literacy, numeracy, and logical reasoning related questions. The second part will assess the student’s aptitude in chosen art forms through a portfolio submission and/or audition and interview. Details of the submission and any audition requirements will be communicated to students after closure of applications.</div>)}
                    {activeTab === "AFPS" && (
                        <div className='para'>
                            Selection process will include an aptitude test, followed by an interview and medical screening.
                            <br />
                            Aptitude test will consist of questions pertaining to Mathematics, English, General Ability (Social sciences, general sciences, general awareness).
                            <br />
                            For students applying to class 9, the syllabus may be aligned to Class 8.
                            <br />
                            Final results will be declared following an interview and medical screening of applicants.
                        </div>
                    )}
                    {activeTab === "HE 21" && (<div className='para'><button onClick={() => window.open(he21Syllabus)}>View Syllabus</button></div>)}
                    {activeTab === "Humanities" && (<div className='para'><button onClick={() => window.open(humanitiesSyllabus)}>View Syllabus</button></div>)}
                    {activeTab === "STEM" && (<div className='para'><button onClick={() => window.open(stemSyllabus)}>View Syllabus</button></div>)}
                    {activeTab === "PVA" && (<div className='para'><button onClick={() => window.open(pvaSyllabus)}>View Syllabus</button></div>)}
                    {activeTab === "AFPS" && (<div className='para'><button onClick={() => window.open(afpsSyllabus)}>View Syllabus</button></div>)}

                    <br />
                    <br />
                    <div className='heading'>Timeline</div>
                </div>
                <div className='timeline'>
                    <img src={AdmissionTimeline} alt="Admission Timeline" />
                </div>
                <div className='apply'>
                    <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")}> Login </button>
                </div>

            </div>
        </div>
    )
}

export default Admissions;