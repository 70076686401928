import React from 'react';
import { LanguageContext } from '../App';
import Aboutdbse from "../assets/images/Group_studnt.png";
import VisionImage from "../assets/images/vision_image.png";
import Breadcrum from '../common/Breadcrum';
import AccreditationsPDF from "../assets/docs/MoE_ LIST OF RECOGNISED BOARDS.pdf";
import { lang } from '../language';
import "./about.scss";

const About = () => {
  const language = React.useContext(LanguageContext)
  return (
    <div>
      <Breadcrum name={"About DBSE"} path="/about/dbse" />
      <div className='about_section'>
        <div className='abtdbse' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.heading }}></div>
        <div className='imgdata'>
          <div dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.imgdata1 }}></div>
          <div className='groupstudentpic'><img src={Aboutdbse} alt="about" /></div>
        </div>

        <div className='imgnextdata' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.imgdata2 }}></div>
        <div className='imgnextdata' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.imgdata3 }}></div>
        
        <br></br>
        <div className='imgnextdata'>
          <a href={AccreditationsPDF} target="_blank" rel='noreferrer'>View Accreditations</a>
        </div>
        

      </div>



      <div className='VisionImage'><img src={VisionImage} alt="about" /></div>
      <div className='about_section'>
        <div className='partnrIB' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.partners.partnrIB }}></div>

        <div className='partnrIS' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.partners.partnrIS }}></div>

        <div className='dbsefocus' dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.dbsefocus.heading }}></div>
        <ul className='datalist'>
          <li dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.dbsefocus.datalist1 }}></li>
          <li dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.dbsefocus.datalist2 }}></li>
          <li dangerouslySetInnerHTML={{ __html: lang[language].aboutDBSE.dbsefocus.datalist3 }}></li>
        </ul>
      </div>
    </div>
  )
}

export default About