import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.scss";
import Banner from "../../assets/images/afps-banner.png";
import { arrowDown, arrowUp } from '../../assets/images/svg';
import Humanities1 from '../../assets/images/why-afps-1.png';
import Humanities2 from '../../assets/images/why-afps-2.png';
import Humanities3 from '../../assets/images/why-afps-3.png';
import Humanities4 from '../../assets/images/why-afps-4.png';

import AFPSStudentTestimonial from '../../assets/images/AFPS_Student_Testimonial.png';
import AFPSTeacherTestimonial from '../../assets/images/AFPS_Teacher_Testimonial.png';
import AFPSPartnerTestimonial from '../../assets/images/AFPS_Partner_Testimonial.png';
// import Dummy from '../../assets/images/dummy.webp';

import SpecilisationBorder from '../../assets/images/specilisation-border.png';
import SpecilisationBorder5 from '../../assets/images/specilisation-border-5.png';
import CareerPathways from '../../assets/images/career_pathways_afps.png';
import afpsCreators from '../../assets/images/afps-creators.png';
import afpsGallery1 from '../../assets/images/afps-1.jpg';
import afpsGallery2 from '../../assets/images/afps-2.jpg';
import afpsGallery3 from '../../assets/images/afps-3.jpeg';
import afpsGallery4 from '../../assets/images/afps-4.jpeg';
import afpsGallery5 from '../../assets/images/afps-5.jpeg';
import Breadcrum from '../../common/Breadcrum';
import SchoolGallery from '../../common/Gallery';
import { LanguageContext } from '../../App';
import { lang } from '../../language';



const AFPS = () => {
    const language = React.useContext(LanguageContext)

    const [showCurriculum, setShowCurriculum] = useState(false);
    const [showCreators, setShowCreators] = useState(false);

    return (
        <div className='specialization'>
            <Breadcrum name={"Armed Forces Preparatory School"} path="/specialisation/humanities" />
            <div className='banner'>
                <img src={Banner} alt="DBSE SOSE" title='DBSE SOSE' />
                <div className="inner-breadcrum-container">
                    <div className="inner-breadcrum" dangerouslySetInnerHTML={{ __html: lang[language].afps.heading }}></div>
                    <div className="inner-breadcrum-hover" dangerouslySetInnerHTML={{ __html: lang[language].afps.fullform }}></div>
                </div>
            </div>
            <div className='specialization-description' dangerouslySetInnerHTML={{ __html: lang[language].afps.description }}></div>
            <div className='specialization-section'>
                <div className='specialization-content'>

                    <div className='why afps'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title }}></div>
                        <div className='cards' >
                            <div className='why-card'>
                                <img src={Humanities1} alt="DBSE SOSE" className="why-icon" />
                                <div className='content'><img src={SpecilisationBorder5} alt="" />
                                    <div dangerouslySetInnerHTML={{ __html: lang[language].afps.content1 }}></div>
                                </div>
                            </div>
                            <div className='why-card'>
                                <img src={Humanities2} alt="DBSE SOSE" className="why-icon" />
                                <div className='content'><img src={SpecilisationBorder5} alt="" />
                                    <div dangerouslySetInnerHTML={{ __html: lang[language].afps.content2 }}></div>
                                </div>
                            </div>
                            <div className='why-card'>
                                <img src={Humanities3} alt="DBSE SOSE" className="why-icon" />
                                <div className='content'><img src={SpecilisationBorder} alt="" />
                                    <div dangerouslySetInnerHTML={{ __html: lang[language].afps.content3 }}></div>
                                </div>
                            </div>
                            <div className='why-card'>
                                <img src={Humanities4} alt="DBSE SOSE" className="why-icon" />
                                <div className='content'><img src={SpecilisationBorder5} alt="" />
                                    <div dangerouslySetInnerHTML={{ __html: lang[language].afps.content4 }}></div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='accordian currriculum'>
                        <div className='outer' onClick={() => setShowCurriculum(!showCurriculum)}>
                            {/* <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title2 }}></div> */}
                            {showCurriculum ? (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title2 }}></div>
                            ) : (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title2Closed }}></div>

                            )}
                            <div className='icon'>{showCurriculum ? arrowUp : arrowDown}</div>
                        </div>
                        {showCurriculum && (
                            <div className='inner'>
                                <div className='currriculum_design'>
                                    <div className='currriculum_grade_section section_change'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].afps.grade }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading left' dangerouslySetInnerHTML={{ __html: lang[language].afps.currriculumheading }}></div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading right' dangerouslySetInnerHTML={{ __html: lang[language].afps.headingright }}></div>
                                            </div>
                                        </div>


                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description left'>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.Mathematics }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.English }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.Hindi }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.Science }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.Individual }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.Entrepreneurial }}></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='currriculum_container'>
                                                <div className='currriculum_description right'>
                                                    <div className='paragraph afps'>
                                                        <div className='title3' dangerouslySetInnerHTML={{ __html: lang[language].afps.title3 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc3 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc4 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc5 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc6 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc7 }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='currriculum_grade_section'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].afps.grade2 }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading left' dangerouslySetInnerHTML={{ __html: lang[language].afps.headingleft }}></div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading right' dangerouslySetInnerHTML={{ __html: lang[language].afps.headingright }}></div>
                                            </div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description left'>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.foundationalSubjects.subject1 }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.foundationalSubjects.subject2 }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.foundationalSubjects.subject3 }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.foundationalSubjects.subject4 }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].afps.foundationalSubjects.subject5 }}></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description right'>
                                                    <div className='paragraph afps'>
                                                        <div className='title3' dangerouslySetInnerHTML={{ __html: lang[language].afps.title3 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc3 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc4 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc5 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc6 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].afps.desc7 }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='horizontal'></div>
                    <div className='accordian'>
                        <div className='outer' onClick={() => setShowCreators(!showCreators)}>
                            <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title4 }}></div>
                            <div className='icon'>{showCreators ? arrowUp : arrowDown}</div>
                        </div>
                        {showCreators && (

                            <div className='inner'>

                                <div className='creators_container'>
                                    <div className='images_container'>
                                        <img src={afpsCreators} alt="AFPS" />
                                    </div>
                                    <div className='content_container'>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].afps.content_container1 }}></p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='career_pathways'>
                <img src={CareerPathways} alt="CareerPathways" />
            </div>

            <div className='elegibility_criteria_section'>
                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].afps.title5 }}></div>

                <div className='elegibility_criteria_container'>
                    <div className='elegibility_criteria_container2'>
                        <p dangerouslySetInnerHTML={{ __html: lang[language].afps.elegibility_criteria_container2 }}></p>
                        {/* <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")} dangerouslySetInnerHTML={{ __html: lang[language].afps.applyNow }} ></button> */}
                    </div>
                    <div className='border'></div>
                </div>

                <div className='map-container'>
                    <iframe
                        src="https://www.google.com/maps/d/embed?mid=1C4Boe3_Dsv4jnX_hxtSHFWYuIZgwCjE&ehbc=2E312F"
                        width="100%"
                        height="100%"
                        title='List Of ASoSE AFPS Schools'
                    />
                </div>

                <div className='title testinomials_heading' dangerouslySetInnerHTML={{ __html: lang[language].afps.testimonials }}></div>
            </div>

            <div className='testimonials_section'>
                <Carousel
                    showArrows={true}
                    autoPlay
                    emulateTouch
                    swipeable
                    stopOnHover
                    infiniteLoop
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                >
                    <div className='testimonials_container'>
                        <div className='image'><img src={AFPSStudentTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].afps.para1 }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].afps.name1 }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].afps.post1 }}></div>

                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={AFPSTeacherTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].afps.para2 }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].afps.name2 }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].afps.post2 }}></div>
                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={AFPSPartnerTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].afps.para3 }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].afps.name3 }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].afps.post3 }}></div>
                        </div>
                    </div>
                </Carousel>

            </div>

            <SchoolGallery images={[
                { src: afpsGallery1, width: 250, height: 152 },
                { src: afpsGallery2, width: 250, height: 152 },
                { src: afpsGallery3, width: 250, height: 152 },
                { src: afpsGallery4, width: 250, height: 152 },
                { src: afpsGallery5, width: 250, height: 152 }
            ]} />

        </div>
    )
}

export default AFPS;