import React from 'react'
import Breadcrum from '../common/Breadcrum';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./faq.scss"

const FAQ = () => {
    const faqs = [
        {
            title: "General",
            data: [
                {
                    ques: "Which classes and specialisations will Dr. B. R. Ambedkar School of Specialised Excellence (ASoSE) offer for admission in Academic Year 2023-24?",
                    ans: `
                    Dr. B. R. Ambedkar Schools of Specialised Excellence will run for classes 9th to 12th
                  <br></br>1. Armed Forces Preparatory School
                  <br />2. High-End 21st Century Skills
                  <br />3. Humanities
                  <br />4. Performing and Visual Arts
                  <br />5. Science, Technology, Engineering, and Mathematics (STEM)
                  <br></br>For the academic year 2023-24 , all specialisation schools are taking admission in class 9th only.
                `
                },
                {
                    ques:
                        "Which Board are the Schools of Specialized Excellence affiliated with?",
                    ans: `Dr. B. R. Ambedkar Schools of Specialised Excellence (ASoSE) are affiliated to Delhi Board of School Education (DBSE).`
                },
                {
                    ques:
                        "Will the DBSE certificate/marksheet hold the same validity as CBSE or any other established board? ",
                    ans: `Yes, DBSE marksheet/certificate is at par with CBSE or other state boards.`
                },
                {
                    ques: "What is the fee structure of ASoSEs?",
                    ans: `No fee is charged  from the students of ASoSE as per Delhi government norms.`
                },
                {
                    ques: "What is the number of ASoSE?",
                    ans: `There are 44 Dr. B. R. Ambedkar Schools of Specialised Excellence spanned  across 36 locations in Delhi, out of which, 8 schools have dual specialisations. Of these 15 are for STEM, 12 for Humanities, 12 for High-End 21st Century Skills, 4 for Performing and Visual Arts, and 1 (Residential) for Armed Forces Preparation.`
                },
                {
                    ques: "How will the ASoSE be different from the other Government Schools?",
                    ans: `In these schools, students will be prepared for targeted and aspirational career pathways. The subjects are  designed in a manner that students are well prepared for the admission processes of top universities and employment opportunities in their domain of Specialization. Additionally, students will get exposure to the career pathways and rigorous training on the skills needed to succeed in those jobs and life.`
                },
                {
                    ques: "Who will be developing and teaching the specialised courses?",
                    ans: `ASoSE has partnered with reputed national and international domain experts to develop the curriculum for specialised subjects. Partners include IIT Delhi, NIFT Delhi, Vidyamandir Classes, Global Music Institute, Manipal Academy of Higher Education, Tata Institute of Social Sciences,  Lend-A-Hand India, among others.
                        <br></br>In some cases, courses will also be taught by faculty deployed by these Knowledge Partners, and in other cases, existing teachers will be trained to teach these courses.
                    `
                },
                {
                    ques: "What will be the medium of instructions in ASOSE?",
                    ans: `In ASoSE, the medium of instruction will be the English language.`
                },
                {
                    ques: "What books / content will be used in ASoSEs?",
                    ans: `Teachers may refer to multiple reference materials to teach in ASoSEs, including the NCERT Textbook. However, students will not be expected to purchase textbooks/workbooks beyond the means provided.`
                }
            ]
        },
        {
            title: "Admissions",
            data: [
                {
                    ques: "How will students be selected for the Dr. B. R. Ambedkar School of Specialised Excellence?",
                    ans: "They will be selected by an assessment of aptitude and interest through a test, and an audition/interview, where necessary as per specialisation opted by the applicant."
                },
                {
                    ques: "W​​hat is the eligibility criteria for getting admission in ASoSE?",
                    ans: `
                        ASoSE admissions are open only for students who are residents of NCT of Delhi (with a valid address proof) and are studying in a recognized school of NCT of Delhi. A minimum of 50% students admitted to ASoSEs will be from Delhi Government or Government Aided Schools. Admission will be based on an aptitude assessment on the chosen domain of specialisation. After qualifying the aptitude test, students will have to produce a pass certificate of their respective previous class (class VIII for admission in class IX during session 2022-23).
                    `
                },
                {
                    ques: "What are the age criteria for admission to ASoSE?",
                    ans: `
                        For students who passed the previous class before academic year 2022-23, the following age criteria will be applicable -
                        <br></br>
                        <table width="70%" style="margin: 0 auto" >
                        <thead  style = "border : solid 1px black; text-align: center" >
                            <tr>
                                <th style="border: solid 1px black; margin-left: 1rem;">Grade</th>
                                <th style="margin-left: 1rem;border: solid 1px black;">Normal Age Criteria (without any relaxation) as on 31/03/2022</th>
                            </tr>
                        </thead>
                        <tbody style="border: solid 1px black;text-align: center">
                            <tr style="border: solid 1px black;">
                                <td style="border: solid 1px black;">9</td>
                                <td style="border: solid 1px black;">Completed the age of 13 years but less than 15 years</td>
                            </tr>
                            
                        </tbody>
                        </table>
                        <br /> Age relaxation is applicable as per Directorate of Education norms.
                    `
                },
                {
                    ques:
                        "Will there be reservation of seats in ASoSEs for different categories? ",
                    ans: `ASoSEs will follow reservation as per norms and guidelines of the Directorate of Education, Government of NCT of Delhi.
                `
                },
                {
                    ques:
                        " Will students from recognised private schools be eligible for admissions?",
                    ans: `ASoSE admissions are open only for students who are residents of NCT of Delhi (with a valid address proof) and are studying in a recognised school of NCT of Delhi. A minimum of 50% students admitted to ASoSEs will be from Delhi Government or Government Aided Schools.`
                },
                {
                    ques: "What documents will be required by the students while filling out the registration form?",
                    ans: "Applicants will be required to upload a recent photograph of himself or herself (ideally a headshot where facial features are clearly identifiable) and a scan/photograph of their signature."
                },
                {
                    ques:
                        " Where and when will we get information about the Aptitude Test and where will it be conducted?",
                    ans: `Following closure of the registration form students will receive information about the Aptitude Test, including their admit cards on their registered phone number and email. Tests will be held in centres across Delhi, and centre information will be communicated to applicants via phone and email following closure of registrations. Please check the website regularly for updates.`
                },
                {
                    ques: " What to do if I have any query/ grievance?",
                    ans: `. In case of any query, applicants may register with their basic details on the ASoSE admissions portal and share their grievances through the ‘Contact us/ Register a query’ section in the application portal.`
                }
            ]
        },
        {
            title: "Armed Forces Preparatory School",
            data: [
                {
                    ques: "For AFPS, will residential and academic blocks be located on the same premises?",
                    ans: "Yes, AFPS, Delhi will be a co-ed residential campus with separate hostels for boys and girls."
                },
                {
                    ques: " Does AFPS, Delhi provide extra classes/special classes/coaching for competitive exams?",
                    ans: "Yes, AFPS will provide SSB Training cycles, Personality development capsules, Spoken English & Verbal Expression training sessions. All the activities will aim to inculcate officer like qualities (OLQs) in the students."
                },
                {
                    ques: "Will AFPS, Delhi  cover any kind of day-to-day expenses for the students?",
                    ans: " Boarding & lodging, uniforms/dresses/kits will be borne by the state as per norms."
                }
            ]
        },
        {
            title: "STEM ASoSE",
            data: [
                {
                    ques: " What would be the Knowledge Partner’s  role in my child’s school education?",
                    ans: "The Knowledge Partner will guide STEM ASoSE students to apply their Science and Mathematics learning to prepare for entrance to premier Higher-Education institutions through examinations such as JEE and NEET."
                }
            ]
        },
        {
            title: "Humanities ASoSE",
            data: [
                {
                    ques: "What will happen in the Humanities ASoSEs beyond the foundational curriculum?",
                    ans: `
                        Apart from foundational subjects, students studying in the Humanities ASoSEs will learn two new subjects:
                        <p class="pl-2">
                        <br />a. Systems and Societies: An integrated and thematic curriculum will be taught in all classrooms that will draw from various disciplines such as Geography, Political Science, Economics, History, Ecology, Culture Studies, etc.
                        <br></br>b. World of Work : An applied learning curriculum that will provide students exposure to several career pathways (such as Journalism, Law, Social Work, Psychology, etc.) that students can take up after higher education along with building the necessary skills to be successful social scientists.
                        </p>
                    `
                },
                {
                    ques: "What knowledge partners are strengthening the teaching and learning process in the Humanities ASoSE?",
                    ans: "​​Several well renowned Knowledge Partners are  on board to support the teaching, curriculum development andlearning process in the Humanities ASoSEs. These include leading schools and universities that design and deliver robust and rigorous social sciences curriculum.  Our partners include Tata Institute of Social Sciences, Vasant Valley and Ashoka University."
                },
                {
                    ques: "What skills will students build in the Humanities ASoSEs?",
                    ans: "​​Students will build several skills through the foundational and specialised curriculum they study at the Humanities ASoSEs. They will go through rigorous training in research, analysis, critical thinking, collaboration and social sciences  methodology with career related skills."
                }
            ]
        },
        {
            title: "Performing and Visual Arts ASoSE",
            data: [
                {
                    ques:
                        " What art forms will be offered in PVA ASoSE(s)?",
                    ans: `
                    Three art forms are being offered in PVA ASoSE(s). These are:
                        <ul style="list-style-type: '- ';">
                            <li>Music</li>
                            <li>Visual Arts</li>
                            <li>Filmmaking Acting & Media Studies</li>
                        </ul>
                    `
                },
                {
                    ques: " On what basis will students be selected in PVA ASoSE(s)?",
                    ans: ` Students will be selected through a two part assessment. The first part will be taken by all students (regardless of chosen art-form) and will consist of literacy, numeracy, and creative aptitude related questions. The second part will assess the student’s aptitude in chosen art form through a portfolio submission and/or audition and interview.`
                },
                {
                    ques: " Will students in PVA ASoSE(s) only study the arts?",
                    ans: `
                        No, students from PVA ASoSE(s) will study a foundational curriculum including Languages, Mathematics, Science, and Social Science in grades 9 and 10. 
                        <br />In grades 11 and 12, students foundational courses will include Language and Communication. In addition to this students may choose from a range of subjects as prescribed by DBSE.
                    `
                },
                {
                    ques: "Will students be tested on the chosen art form? Will these marks be added to the overall grade percentage?",
                    ans: ` ​​Yes, students will be formally assessed on their learning levels in PVA subjects in addition to foundational subjects both of which will be presented in students’ report cards.`
                },
                {
                    ques: "What kind of exposure activities outside of school will be facilitated for students?",
                    ans: "Students will be given exposure to the field of PVA through a range of off-site visits to music studios, art galleries, stage productions, competitions, film studios etc. as per relevance to the chosen PVA subjects."
                },
                {
                    ques:
                        "Will students be able to build a full-time career in their chosen art form (Music/Visual Art/Filmmaking, Acting and Media Studies)?",
                    ans: `
                        There is a wide range of career options available to students pursuing PVA forms. These are:
                        <br></br>
                        <ul style="padding-left: 1.5rem;">
                            <li style="list-style: disc;"><b>Music</b> - performer musician, music producer, music director, music strategist, teacher etc.</li>
                            <li style="list-style: disc;"><b>Visual arts</b> - illustrator, animator, fashion designer, space designer, graphic design, teacher etc.</li>
                            <li style="list-style: disc;"><b>Filmmaking, Acting & Media Studies</b> – Filmmaker, Film editor, Film post-production artist, Actor, Social Media writer, Journalist, News Reporter etc.</li>
                        </ul>
                        <br />
                        In PVA ASoSEs, students will be prepared towards pursuing full-time careers in any of these fields, based on student interest.
                    `
                },
                {
                    ques:
                        " What kind of practical exposure in chosen art form will students be given?",
                    ans: ` Students will prepare their own portfolio which will require them to practically execute the skills developed during classes
                        <br></br> In addition, short-term projects, internships, and participation in competitions will also be a major part of the curriculum in PVA schools. 
                    `
                }
            ]
        },
        {
            title: "High-End 21st Century Skills ASoSE",
            data: [
                {
                    ques: "What specialisations would be offered to students?",
                    ans: "Students would be exposed to a range of specialisations in grades 9 and 10 and they can choose to specialise in one of the areas in Grade 11 and 12. Students would be offered – Coding, Digital media and design, Electro mechanical production, Finance and accounts, Fashion Design & Aesthetics and Robotics. More specialised courses can be added in the future. "
                },
                {
                    ques: "What will students be able to pursue after studying in this school?",
                    ans: ` Students will be able to pursue higher education in an area of specialisation but also take employment or start their own businesses based on the skills that they acquire in 11th and 12th grade.`
                },
                {
                    ques: "What support would be provided to students to ensure that they build depth and competency in required skill?",
                    ans: `Students will be supported to build skills in their areas of specialisations in many ways such as:
                        <ul style="list-style-type: '- ';">
                            <li>Partnerships with top organisations in area of specialisation</li>
                            <li>Labs, infrastructure and latest equipment for practical hands on learning</li>
                            <li>Guest sessions, masterclasses by industry experts to inspire students</li>
                            <li>Deep industry linkages through internships, projects etc.</li>
                        </ul>
                    `
                }
            ]
        },


    ]
    return (
        <div className='faq'>
            <div className='faq_section'>
                <Breadcrum name={"FAQ"} path={"/faq"} />
                <div className='faq_container'>
                    {faqs.map((item, i) =>
                        <div style={{ margin: i !== 0 ? "1rem" : "" }} key={i}>
                            <h3 className='title' style={{ margin: "0.7rem 1.2rem" }}>
                                {item.title}
                            </h3>
                            <Accordion allowZeroExpanded={true}>
                                {item.data &&
                                    item.data.map((abc, j) =>
                                        <AccordionItem key={j}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {"Q. "} {abc.ques}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div dangerouslySetInnerHTML={{ __html: abc.ans }} />
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    )}
                            </Accordion>
                        </div>
                    )}
                    <div style={{ padding: "0.5rem 1.5rem", fontWeight: "700" }}>*For any other queries write to us on: <a href='mailto:sose@doe.delhi.gov.in'>sose@doe.delhi.gov.in</a></div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;