export const lang = {
    en: {
        home: {
            updatesHeading: "Updates",
            updates: `
                Admissions for Class 9 in all specialisations for Academic Year 2023-24 are now closed. 
                &emsp; 
                <a href='https://www.edudel.nic.in//sose/admission/Home.aspx' target="_blank" rel='noreferrer'>Login</a>. 
                &emsp; | &emsp; 
                All candidates who have qualified for Performing and Visual Arts ASoSE Test (Round 2) can now download their Round 2 Admit Card(s) from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.
                &emsp; | &emsp; 
                All candidates who have qualified for <b>Humanities ASoSE Test (Round 2)</b> can now download their Round 2 Admit Card from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.
                &emsp; | &emsp; 
                All candidates who have qualified for <b>High End 21st Century Skills (STAGE 2 (Interview))</b> can now download their Round 2 Admit Card from the ASoSE admissions portal. Please carefully check the instructions on the Admit Card.
                `,
                dcmMessage: `"<i>We are living in an era of specialisation and excellence. With Dr. B.R. Ambedkar Schools of Specialised Excellence, we are bringing world-class education to our students, so that they are ready for the next generation challenges.</i>"`,
                dcmName: `Shri Manish Sisodia`,
            dcmPosition: `Deputy Chief Minister and Education Minister, Government of NCT Delhi`,
            specialization: {
                heading: `Domains Of <span>Specialisation</span>`,
                stem: `Science, Technology, Engineering and Mathematics (STEM)`,
                he21: `High-end 21st Century Skills`,
                humanities: `Humanities`,
                pva: `Performing and Visual Arts`,
                afps: `Armed Forces Preparatory School`,
                viewMore: `View More`,
            },
            notices: {
                heading: `Notices <span>&amp;</span> Updates`,
                updates: `
                <ul>
                    <li><a href='https://www.edudel.nic.in//sose/admission/Home.aspx'>Admissions for Class 9 in all specialisations for Academic Year 2023-24 are now open.</a></li>
                </ul>
                `,
            },
            partners: `Partners`,
        },
        aboutASOSE: {
            heading: `<span>About</span> ASOSE`,
            intro: `Dr. B.R. Ambedkar Schools of Specialised Excellence are choice-based schools for grades 9 to 12 that allow students to specialise in their chosen fields of study. The <strong>Government of NCT of Delhi</strong> has established Dr. B.R. Ambedkar Schools of Specialised Excellence in order to cater to students who have a demonstrated interest and aptitude in specific domains.`,
            vision: {
                vision: `Vision <br /><span>and Mission</span>`,
                mission: `These schools aim to nurture students for all-round development and, at the same time set
                them up their chosen area of specialisation. Students in Ambedkar SOSEs receive targeted
                preparation higher education pathways and career opportunities through:`,
            },
            Infrastructure: {
                infra1: `World class infrastructure and top teachers to transact new- age curriculum and assessment
                in the specialised domains.`,
                infra2: `Partnership with reputed organisations, premier universities and globally & locally
                acclaimed professionals.`,
                infra3: `Increased exposure to various career pathways through master classes, expert interactions,
                field visits etc.`,
            },
            ASoSEStoryHding: {
                heading: `The ASOSE <br /><span>Story</span>`,
                ASoSEStoryfirst: `The contemporary system provides students with a standardised, one-size-fits-all education. This leaves little scope for students, who have early awareness and interest in specific fields, to pursue specialisations of their choice. Additionally, the current education system puts little emphasis on aligning the curriculum and assessment with higher education and industry requirements. Therefore, the Delhi government envisioned a new set of schools that would create a new hallmark of secondary education.`,
                ASoSEStorysecond: `In further pursuance, a survey was conducted by the Directorate of Education, GNCTD during 2020-2021,
                with over 3200 students and parents, which suggested that:`,
            },
            Card1: {
                number: `73%`,
                subhead: `Students from grades 8-10<br />&`,
                number1: `70%`,
                subhead1: `Students from grades 11-12`,
                desc: `expressed interest in pursuing their <i>extra-curricular</i> interest as formal study`,
            },
            Card2: {
                number: `79%`,
                subhead: `Students from grades 8-10<br />&`,
                number1: `88%`,
                subhead1: `Students from grades 11-12`,
                desc: `expressed an interest in the idea of <i>early specialisation</i> in their subjects of interests.`,
            },
            Card3: {
                number: `71%`,
                subhead: `Parents of students from grades 8-10<br />&`,
                number1: `66%`,
                subhead1: `Parents of students from grades 11-12`,
                desc: `indicated that they would support their children in pursuing <i>extra-curricular</i> interests through formal study or as a career.`,
            },
            Card4: {
                number: `75%`,
                subhead: `Parents of students across grades 8-10<br />&`,
                desc: `believed<i>early specialisation</i>would be beneficial for their child.`,
            },
            subDesc: `<b>Keeping these findings at the centre, Ambedkar SOSEs were established in 2021.
            Ambedkar SOSEs have the following specialisations:</b>`,
            logo1: {
                subject: `Science, Technology, Engineering and Mathematics (STEM)`,
                subjectdetails: `Rigorous STEM-based education from the leading minds in the industry for preparing students for higher education examinations like JEE & NEET`,
            },
            logo2: {
                subject: `, Humanities`,
                subjectdetails: `Building critical thinking and
                research mindset in students, to help them become leading
                social scientists, lawyers, journalists, development professionals and more.`,
            },
            logo3: {
                subject: `High-end 21st Century Skills`,
                subjectdetails: `Focused on skill and entrepreneurship
                development that will allow students to be ready for 21st century job roles
                as well as further studies at the University level.`,
            },
            logo4: {
                subject: `Performing and Visual Arts`,
                subjectdetails: `Through tie-ups with renowned organisations,
                students will be trained rigorously to achieve finesse in
                their chosen field of art (Music/ Visual Arts/ Filming, Acting etc.)`,
            },
            logo5: {
                subject: `Armed Forces Prepatory School`,
                subjectdetails: `AFPS will prepare students for
                entry to Indian defence service examinations
                such as for the Army, Navy, and Airforce.`,
            },
            Map: `By the academic year 2023-24, 44 ASOSEs will be spread across 36 campuses`,
            Affiliation: {
                heading: `Affiliation`,
                define: `Dr. B.R Ambedkar Schools of Specialised Excellence are affiliated to the Delhi Board of School Education (DBSE). They are designed as per the philosophy of DBSE that centers around moving away from rote memorisation through integrating assessment into the everyday practice of teaching-learning and using assessments for learning rather than restricting them to only being assessments of learning. DBSE is collaborating with the International Baccalaureate (IB), a global community of educationists, that has engaged with 5,500 schools across 159 countries.`,
            },
        },
        aboutDBSE: {
            heading: `About <span>DBSE</span>`,
            imgdata1: ` Most assessment systems evaluate students solely based on a semester-end, marks-based  examination. This puts a lot of emphasis on learning by rote and memorizing textbooks. It also has very strict rules about which subjects can be studied and how exams are set up. High-stakes board exams have become a source of extreme stress for many students, also adversely impacting their wellbeing.`,
            imgdata2: `There is barely any focus on making assessment a regular part of the teaching-learning experience and using assessments as tools for learning rather than solely to evaluate student progress.  Carefully designed assessments can help students improve their practical, creative, analytical, and problem-solving skills.`,
            imgdata3: `With this context in place, the Government of NCT of Delhi launched the Delhi Board of School Education (DBSE). The DBSE advocates for a new assessment system that  enables students' holistic development. It aims to shift the focus of assessment and prepare students for future challenges in a stress-free environment. Supplemented with the cutting-edge curricula of Ambedkar SOSE , students will be prepared to pursue higher education in premier institutions as well as the new-age employment opportunities of the 21st century.`,
            partners: {
                partnrIB: `<b>Partnership with the <span>International Baccalaureate (IB):</span></b>`,
                partnrIS: `DBSE has partnered with IB to develop a curriculum that matches up to international standards.`,
            },
            dbsefocus: {
                heading: `<b>The focus is on :</b>`,
                datalist1: `Inquiry-based educational philosophy and implementation, which is designed to enhance the agency of the student.
                Additionally, it trains the teacher to integrate global contexts into everyday classroom teaching and learning.`,
                datalist2: `Provision of transdisciplinary, interdisciplinary, multidisciplinary, and skill-based curriculum frameworks with
                room to adapt them as per local context.`,
                datalist3: `Child-centric assessment, which focuses not only on retaining knowledge, but also understanding key concepts and mastering skills.
                They involve multiple assessment strategies such as essays, structured problems, projects, and case studies, amongst many others.`,
            },
        },
        afps: {
            heading: `AFPS`,
            fullform: `Armed Forces Preparatory School`,
            description: `The Shaheed Bhagat Singh - Armed Forces Preparatory School (SBS-AFPS), Delhi is a flagship project of the Delhi state government aiming to bring fruition to the dreams of many students filled with zeal and enthusiasm for serving the nation through the armed forces.
            <br />
            AFPS is an institute dedicated to training and preparing students from the 9th to 12th grades for admission to the National Defense Academy (NDA) and the Indian Army, Navy, and Air Force, as well as other uniformed services.`,
            title: `Why <br /><span>SBS-AFPS?</span>`,
            content1: `Rigorous preparation for NDA and its equivalent examination`,
            content2: `Exclusive Services Preparatory Wing to inculcate Officer Like Qualities in students`,
            content3: `Expert faculty comprising of retired Armed Forces officers and trained instructors`,
            content4: `Free co-educational school with separate residential facilities for boys and girls`,
            title2: `Curriculum <br /><span>Design</span>`,
            title2Closed: `Curriculum <span>Design</span>`,
            grade: `Grade 9 & 10`,
            currriculumheading: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
            Mathematics: `Mathematics`,
            English: `English`,
            Hindi: `Hindi`,
            Science: `Science`,
            Individual: `Individual & Societies`,
            Entrepreneurial: `Entrepreneurial Mindset and Digital Design`,
            grade2: `Grade 11 & 12`,
            headingleft: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
            headingright: `Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i>`,
            foundationalSubjects: {
                subject1: "Mathematics ",
                subject2: "English",
                subject3: "Physics",
                subject4: "Chemistry",
                subject5: "Computer Science/Biology/Physical and Health Education",
            },
            title3: `Service preparatory wing:`,
            desc2: `Dedicated sessions for General Awareness/Current affairs, exam techniques and time management skills for written exams`,
            desc3: `Special classes for familiarising students with exam pattern`,
            desc4: `Training for psychological tests`,
            desc5: `Personality development workshops`,
            desc6: `Individual & group tasks activitiy`,
            desc7: `Dedicated Mock interview public speaking sessions`,
            title4: `Knowledge <span>Partners</span>`,
            content_container1: `An expert faculty composed of subject matter experts with a successful conversion record prepares the students for the written component for competitive exams. Training and preparation for NDA - specific components are led by decorated retired Armed Forces officers and trained instructors.`,
            title5: `Eligibility <br /><span>Criteria</span>`,
            elegibility_criteria_container2: `Admissions to Shaheed Bhagat Singh Armed Forces Preparatory School is open to students for Class 9. Students of Delhi (with valid residence proof) studying in Government, Government Aided or Recognised Schools of Delhi are eligible to apply for admission. Students must produce valid residence proof of Delhi at the time of admission. At least 50% students admitted to Schools of Specialized Excellence will be from Government or Government Aided Schools. Admission will be determined on the basis of  a written selection test, followed by an interview and finally a medical examination based on a defined set of requirements that are equivalent to that of medical examination for the National Defence Academy. After qualifying the test, students will have to produce a pass certificate of their respective previous grade (grade 8 for admission in grade 9 during session 2023-24). Please check the admissions page for more details of the selection procedure.`,
            applyNow: `Apply Now`,
            testimonials: `Testimonials`,
            para1: `“I joined this school because I knew it will help me in my overall growth. Our daily routine includes regular classes and physical training and along with this, we have a dedicated time where we study for competitive exams. In our school, we play various types of sports which help in physical training. I believe this school will make me independent in my life. I will have a good level of competition here.”`,
            name1: `Student`,
            post1: `SBS-AFPS`,
            para2: `“Shaheed Bhagat Singh Armed Forces Preparatory School, Jharoda Kalan has a unique educational environment. It is a place where we have committed ourselves to the vision of raising achievement, raising aspiration and enriching partnerships. As Head of School, I feel proud and privileged to be a part of an educational institution where every stakeholder is a learner and every day is an opportunity to learn and discover.”`,
            name2: `HOS`,
            post2: `SBS-AFPS`,
            para3: `“The course has been designed keeping in mind the demands of the NDA exam. Since the exam is conducted by UPSC it is of a level which ensures that a well informed and enlightened citizen, free from any kind of prejudice does well in the general awareness section. Student would be able to appreciate the country’s diversities ,its rich history along with the  present which has a number of challenges and a future which is both bright and safe in the hands of our patriotic youth and especially those in the service of the motherland.”`,
            name3: `Mr. Irfan Shamim`,
            post3: `UPSC GS Faculty, SBS-AFPS`,
        },
        he21: {
            inner: `HE 21`,
            breadcrumHover: `High-End 21st Century Skills`,
            specializationdescription: ` High-End 21st Century Skills Ambedkar SOSEs (HE21 Ambedkar SOSEs) aim to provide students with aspirational careers. The schools prepare students for success in a wide range of emerging and high-demand fields by developing industry-oriented specialised skills. Masterclasses, internships, immersion visits, and live projects with industry partners provide students with opportunities to learn by doing and help them build industry-relevant skill sets.
      <br />
      In grades 9 and 10, there are six taster courses being offered by HE 21 schools under two subject areas. In grades 11 and 12, students would choose one specialisation in which they would build their skills and expertise.`,
            title: `Why <br /><span>HE 21<br /> ASoSE?</span>`,
            Card1: `6 taster courses each year for grades 9-10 & 1 specialisation for grade 11-12.`,
            Card2: `New-age skills for enterprising 21st-century careers.`,
            Card3: `Immersive labs, Industry Visits, Masterclasses & Employment-readiness avenues which go beyond classroom.`,
            Card4: `Stellar curriculum committee of industry professionals, academicians and global educators such as IIT Delhi, NIFT Delhi.`,
            title2: `Curriculum <br /><span>Design</span>`,
            title2Closed: `Curriculum <span>Design</span>`,
            grade: `Grade 9 & 10`,
            currriculum_heading_left: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
            currriculum_heading_right: `Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i>`,
            Mathematics: `Mathematics`,
            English: `English`,
            Hindi: `Hindi`,
            Science: `Science`,
            IndividualSocieties: `Individual & Societies`,
            Entrepreneurial: `Entrepreneurial Mindset and Digital Design`,
            title3: `Advanced Technologies -`,
            sub_title1: `1. Coding`,
            desc1: `The course covers the fundamentals of app development, web development, and game development so that students can work on real-world and industry-relevant projects. The focus is to build problem-solving, logical and algorithmic skills in the students.`,
            sub_title2: `2. Robotics and Automation`,
            desc2: `Students will learn about the main building blocks of robotics systems and their functioning. The course will comprise of understanding the concepts of robotics, drones, Artificial Intelligence(AI), automation and smart manufacturing, and programming techniques to make robotic systems work.`,
            sub_title3: `3. Electro-mechanical Production`,
            desc3: `EMP targets building the concepts and skills which are relevant to the fields of electrical and mechanical. Students will learn about modern machines and how their different sub-components (micro-controllers, sensors, etc) come together to make machines work.`,
            title4: `Design and Commerce -`,
            sub_title4a: `1. Fashion Design and Aesthetics`,
            desc4a: `The course aims to teach theoretical and practical aspects of fashion and textiles through hands-on learning viz block printing, dyeing, etc. The course covers the key components of fashion design - from traditional to contemporary so that students develop an understanding of user needs and contexts and develop aesthetic and design sensibilities.`,
            sub_title4b: `2. Digital Media and Design`,
            desc4b: `Students learn design techniques and venture into domains such as graphics, animation, and UI/UX. They will be able to understand design as an approach to solving problems, delve into the design process and connect it with people, enterprises and communities.`,
            sub_title4c: `3. Finance and Accounting`,
            desc4c: `This course aims to bring the modern concepts of finance and accounting inside the classroom. It covers the aspects of budgeting, banking, financial markets, and fin-tech, and exposes students to real-world applications of finance and accounting.`,
            grade11_12: `Grade 11 & 12`,
            Foundational_Subjects: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
            Specialised_Subjects: `Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i>`,
            Mathematics1: `Mathematics`,
            English1: `English`,
            Physics: `Physics`,
            Chemistry: `Chemistry`,
            Entrepreneurship: `Business Studies/Entrepreneurship`,
            Economics: `Economics`,
            currriculum_container: {
                title: `Coding`,
                desc1: `Web development`,
                desc2: `App development`,
                desc3: `Data Science`,
            },
            paragraph1: {
                title: `Robotics & Automation`,
                desc1: `Robotics and Automation - Foundation`,
                desc2: `Robotics and Autonomous systems`,
                desc3: `Industry and Smart Manufacturing`,
                desc4: `AI, ML in Robotics and Automation Systems`,
            },
            paragraph2: {
                title: `DMD`,
                desc1: `Animation`,
                desc2: `Graphics Design`,
                desc3: `UI/UX`,
            },
            paragraph3: {
                title: `Fashion Design and Aesthetics`,
                desc1: `Fashion Representation & Digital Design`,
                desc2: `Fashion and its Business`,
                desc3: `Design Process & Product Realization`,
            },
            paragraph4: {
                title: `Finance and Accounting`,
                desc1: `Financial Management & Investing`,
                desc2: `Accountancy`,
            },
            paragraph5: {
                title: `Electro-mechanical Production`,
                desc1: `PLC & Automation`,
                desc2: `Mechatronics`,
            }
        },
        Humanities: {
            inner_breadcrum: `Humanities`,
            inner_breadcrum_hover: `Humanities`,
            specialization_description: `The vision of Humanities Ambedkar SOSE is to develop future leaders, innovators, researchers and top global professionals. Humanities Ambedkar SOSEs will prepare students  to access aspirational careers such as law, journalism, urban planning, teaching, civil services, content writing, public policy, social work among others, by introducing these career skills in the school itself. The  students will receive training in 21st century skills such as research, collaboration, critical thinking, and communication. We support students to make informed decisions about their educational and career pathways that fit their personal goals by preparing them for competitive examinations like CUET and other higher education exams.`,
            whytitle: `Why <br /><span>Humanities<br /> ASoSE?</span>`,
            why_card1: `Choose from a wide range of new, cutting-edge humanities subjects`,
            why_card2: `Learn and interact with leading experts from Social Sciences`,
            why_card3: `Combine academics with career-related learning with exposure to more than 15+ careers.`,
            why_card4: `Learning through research projects, community immersion, field visits and expert speakers.`,
            why_card5: `Readiness for top Universities exams like CUET`,
            CurriculumDesign: {
                title: `Curriculum <br /><span>Design</span>`,
                titleClosed: `Curriculum <span>Design</span>`,
                grade9_10: `Grade 9 & 10`,
                currriculum_subjects_left: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
                currriculum_subjects_right: `Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i>`,
                Mathematics: `Mathematics`,
                English: `English`,
                Hindi: `Hindi`,
                Science: `Science`,
                Individual: `Individual & Societies`,
                Entrepreneurial: `Entrepreneurial Mindset and Digital Design`,
                currriculum_description_right: {
                    title1: `Systems and Societies`,
                    desc1: `The themes of climate change, international relations, modern history and transportation are a few examples of the exciting and new-age topics covered in Systems and Societies. Students learn to apply concepts from disciplines like sciences, economics, political science, history, and geography to these themes.`,
                    title2: `World of Work`,
                    desc2: `Students learn core skills and concepts used in social sciences' professions such as transmedia storytelling, public policy, justice and constitution. Extensive skill-building through expert teaching, field visits, guest speakers, and group projects provides students with the relevant field immersion.`,
                },
                grade11_12: `Grade 11 & 12`,
                currriculum_subjects: `Subjects <br /> <i>( 6 Hrs/Day )</i>`,
                Social_Sciences: `Mathematics for Social Sciences`,
                English1: `English`,
                Interdisciplinary: `Interdisciplinary Studies(IDS)`,
                World_of_Work: `World of Work`,
                Any_two: `Any two electives from a wide range of social science subjects such as History, Economics, Political Science,Geography, etc,.`,
            },
            Knowledge_Partners: {
                title: `Knowledge <span>Partners</span>`,
                Vasant_Valley: `<strong>Vasant Valley: </strong>The institution specialises in teaching-learning pedagogy of  International Curriculum followed by developing qualities of leadership and global citizenship among its learners.<br /><a href="https://www.vasantvalley.org/" >https://www.vasantvalley.org/</a>`,
                Institute_of_Social_Sciences: `<strong>Tata Institute of Social Sciences: </strong>It is one of India's foremost universities in the field of Social Sciences. TISS offers   some of the most contemporary and socially relevant, globally acknowledged study programs in social sciences. <br /><a href="www.tiss.com" >www.tiss.com</a>`,
                Ashoka_University: `<strong>Ashoka University: </strong>It is one of the leading universities of Liberal Arts and Social Sciences in India. It helps students gain both foundational knowledge and an in-depth understanding of multiple disciplines. The aim of their education is to equip students with skill sets such as  complex problem solving, critical thinking, teamwork, effective communication, and innovation for impact and change. <br /><a href="www.ashoka.com" >www.ashoka.com</a>`,
                teachers: `<i>The teachers of Dr. Ambedkar School of Specialised Excellence are trained extensively under the guidance of mentors from leading social sciences universities and institutions like Tata Institute of Social Sciences, Vasant Valley and Ashoka University.</i>`,
            },
            Eligibility: {
                title: `Eligibility <br /><span>Criteria</span>`,
                text: `Admissions to Ambedkar ASOSE Humanities is open to students for Class 9. Students of Delhi (with valid residence proof) studying in Government, Government Aided or Recognised Schools of Delhi are eligible to apply for admission. Students must produce valid residence proof of Delhi at the time of admission. At least 50% students admitted to Schools of Specialized Excellence will be from Government or Government Aided Schools. Admission will be determined on the basis of selection tests. After qualifying the aptitude tests, students will have to produce a pass certificate of their respective previous grade (grade 8 for admission in grade 9 during session 2023-24). Please check the admissions page for more information regarding the selection test.`,
                Apply_Now: `Apply Now`,
            },
            Testimonials: `Testimonials`,
            para: `“Humanities Ambedkar SOSEs gave me the opportunity to learn through projects and practical work. A moot court gave me an insight into law, a youth parliament exposed me to political science, and a museum trip gave me an insight into history.”`,
            name: `Student`,
            post: `ASOSE - Humanities`,
            para1: `“Teaching in Ambedkar SOSE Humanities is an amazing experience. Specialised subjects are innovative and we facilitate the classes with innovative methods. I truly enjoy my students doing research work, making projects, working on chrome books, watching videos, doing group work, etc. All this is so interactive and informative, that now students have started thinking out of the box. They come up with fabulous ideas. I feel so satisfied and content. In  my many years of  teaching career, I have never felt like this before.”`,
            name1: `Facilitator, System and Societies`,
            post1: `ASOSE - Humanities`,
            para2: `“For having a successful career, a person should gain efficiency in multiple skill  sets. Through the different modules in the World of Work subject taught at Ambedkar SOSE Humanities classroom, students are equipped to develop different skills like: critical thinking, questioning skills, application of concepts, data analysis and communication skills.”`,
            name2: `Tanya Mittal`,
            post2: `TISS`,
        },
        pva: {
            heading: {
                inner_breadcrum: `PVA`,
                inner_breadcrum_hover: `Performing and Visual Arts`,
                specialization_description: ` Performing and Visual Arts Ambedkar SOSEs (PVA Ambedkar SOSEs) enable a well-rounded education experience - best suited for students who are passionate about arts and keen to eventually pursue a successful career in the field not just as performing artists but also in roles related to creative industries. Students can specialise in their chosen art form and study one of the three art forms offered - Music (Indian and Western), Visual Arts and Film, Acting & Media Studies.
                    <br />The uniquely crafted curriculum and exposure offered open doors for higher education and career pathways in the chosen field of art by the support of guidance, hands-on training, and one-on-one mentoring.`,
                specialization_section: {
                    why_card: {
                        title: `Why <br /><span>PVA <br />ASoSE?</span>`,
                        card1: `Learning directly from industry professionals as faculty`,
                        card2: `Practice on diverse musical instruments, high-quality art material and sophisticated cinematic devices`,
                        card3: `Learn by visiting art galleries, film studios, and music studios`,
                        card4: `Exciting courses in Product Design/Sculpture, Vocals/Exciting, Advertising/Filmmaking`,
                        card5: `Readiness for CUET/NID/NIFT and other such exams`,
                    },
                },
                CurriculumDesign: {
                    title: `Curriculum <br /><span>Design</span>`,
                    titleClosed: `Curriculum <span>Design</span>`,
                    Grade_9_10: `Grade 9 & 10`,
                    currriculum_heading_left: `Foundational Subjects <br /> <i>(3-4 Hrs/Day)</i>`,
                    currriculum_heading_right: `Specialised Subjects <br /> <i>(2-3 Hrs/Day)</i>`,
                    subjects: {
                        Mathematics: `Mathematics`,
                        English: `English`,
                        Hindi: `Hindi`,
                        Science: `Science`,
                        Individual_Societies: `Individual & Societies`,
                        Entrepreneurial: `Entrepreneurial Mindset and Digital Design`,
                    },
                    currriculum_description_right: {
                        paragraph1: {
                            title: `Music -`,
                            sub_title1: `1. Music Core (Indian Music Foundation, Western Music Foundation)`,
                            desc1: `Students will develop foundational knowledge of Western and Indian music with an overarching theoretical, practical, and cultural context.`,
                            sub_title2: `2. Applied Music & Instrument Studies`,
                            desc2: `Students choose a primary instrument or voice specialisation, on which they progressively build technical skills and stylistic expression.`,
                        },
                        paragraph2: {
                            title: `Visual Arts -`,
                            sub_title1: `1. Visual Art Development and Practice`,
                            desc1: `Students will develop crafting skills through in-depth exposure to visual art tools, techniques, and methods, customised to the real world of work and industry.`,
                            sub_title2: `2. Creative Expression and Analytical Thinking`,
                            desc2: `Students will build a connection between research, practice, and an internal creative process in the creative arts. It will help them to develop a deep understanding of art & design practice.`,
                        },
                        paragraph3: {
                            title: `Filmmaking, Acting and Media Studies -`,
                            sub_title1: `1. Film and Cinematic Arts`,
                            desc1: `Students will learn the skills, business, and craft of film and visual content production. It will enhance creative and technical skills and develop the capacity for organised thinking and practical application.`,
                            sub_title2: `2. Media and Communication`,
                            desc2: `Students will get a platform to explore and recognize media as a tool of communication. They will learn to study aspects of print media, broadcast media and new media.`,
                        },
                    },
                    Grade_11_12: {
                        title: `Grade 11 & 12`,
                        currriculum_heading: `Subjects <br /> <i>( 6 Hrs/Day )</i>`,
                        subject: {
                            English: `English`,
                            Mathematics: `Mathematics/ choice between 2-3 Humanities subjects`,
                            Business: `Business Strategy`,
                            specialisation: `3 specialisation courses of chosen art form`,
                        },
                    },
                },
                Knowledge_Partners: {
                    title: `Knowledge <span>Partners</span>`,
                    Global_Music_Institute: `<strong>Global Music Institute: </strong>Global Music Institute (GMI) is India’s leading contemporary music institute offering innovative programs that enable young musicians to shape their future in the global music industry. They work to promote cross-cultural musical dialogue and exchange, especially between contemporary and more traditional styles of music through its diverse international faculty.<br /><a href="https://globalmusicinstitute.in/" >https://globalmusicinstitute.in/</a>`,
                    Srishti_Manipal_Institute: `<strong>Srishti Manipal Institute: </strong>Srishti Manipal's mission is to creatively impact ecosystems and build new ecosystems that produce creative competencies ahead of the curve. Towards this mission, the institution entrusts itself with the role of producing capabilities through its professional, vocational, and research programs.<br /><a href="http://srishtimanipalinstitute.in/" >http://srishtimanipalinstitute.in/</a>`,
                    Whistling_Woods_Institute: `<strong>Whistling Woods Institute: </strong>Whistling Woods International (WWI) is India’s premier institute of Film, Communication & Creative Arts. The curriculum at WWI is a unique blend of theoretical and practical, ensuring that the education of the creative aspects of an industry dovetails well with the business aspects.<br /><a href="https://www.whistlingwoods.net/" >https://www.whistlingwoods.net/</a>`,
                    para: `<i>Music faculties have done Masters in Hindustani Music, Diploma in Audio Engineering, Music Production and Indian Classical Vocal etc from esteemed institutions like University of Delhi, Berklee Online, GMI, Trinity Laban, Prayag Sangeet Samiti etc. Some of the faculties of FAM are also teaching at college level in the institutions like Jawaharlal Nehru University. As professional practitioners of art, most have shown their artwork at galleries and other major art institutions in the country.</i>`,
                },
                elegibility_criteria: {
                    title: `Eligibility <br /><span>Criteria</span>`,
                    text: `Admissions to Ambedkar SOSE-Performing and Visual Art is open to students for Class 9. Students of Delhi (with valid residence proof) studying in Government, Government Aided or Recognised Schools of Delhi are eligible to apply for admission. Students must produce valid residence proof of Delhi at the time of admission. At least 50% students admitted to Schools of Specialized Excellence will be from Government or Government Aided Schools. Admission will be determined on the basis of selection tests. After qualifying the aptitude tests, students will have to produce a pass certificate of their respective previous grade (grade 8 for admission in grade 9 during session 2023-24). Please check the admissions page for more information regarding the selection test.`,
                    ApplyNow: `Apply Now`
                },
                Testimonials: {
                    title: `Testimonials`,
                    container1: {
                        para: `“This school has assisted me in developing my relationship with visual art, and by practicing various techniques, I am now confident in pursuing a career in it”`,
                        name: `Student`,
                        post: `ASOSE - PVA`,
                    },
                    container2: {
                        para: `“It intrigues me to be present amidst such imaginative bright kids. Every day has been learning. I believe dealing and connecting with kids from this generation requires empathy. And it is one such area I have been working on. Being able to have unfiltered communication with the students has truly helped me develop constructivism in the classroom. Using creative ways to base the units on experiential learning processes”`,
                        name: `Facilitator`,
                        post: `Visual Arts, ASOSE - PVA`,
                    },
                    container3: {
                        para: `“To help students gain a footing in the dynamic field of performing arts, a comprehensive music program has been designed by GMI for grades 9-12 of PVA Ambedkar SOSE to inspire the music students to develop their own voice as performers, composers, music producers/engineers. A large emphasis is on the holistic development and personal growth of students to ensure they graduate as all-rounded musicians with a deep awareness and understanding of musical diversity and their own artistic expression.”`,
                        name: `Megha Balani Strategy Head`,
                        post: `GMI`,
                    },
                }
            },
        },
    },
};
