import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import './App.css';
import Header from './common/Header';
import Home from './pages/Home';
import About from './pages/About';
import AboutASoSE from './pages/AboutASoSE';
import Humanities from "./pages/Specializations/Humanities";
import STEM from "./pages/Specializations/STEM";
import PVA from "./pages/Specializations/PVA";
import HE21 from "./pages/Specializations/HE21";
import AFPS from "./pages/Specializations/AFPS";
import Admissions from "./pages/Admissions";
import FAQ from "./pages/FAQ";
import { Footer } from "./common/Footer";

export const LanguageContext = React.createContext();
function App() {

  const [language, setLanguage] = useState("en");

  return (
    <div>
      <LanguageContext.Provider value={language}>
        <BrowserRouter>
          <Header language={language} setLanguage={setLanguage} />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about/dbse" element={<About />} />
            <Route exact path="/about/sose" element={<AboutASoSE />} />
            <Route exact path="/specialisation/humanities" element={<Humanities />} />
            <Route exact path="/specialisation/stem" element={<STEM />} />
            <Route exact path="/specialisation/pva" element={<PVA />} />
            <Route exact path="/specialisation/he21" element={<HE21 />} />
            <Route exact path="/specialisation/afps" element={<AFPS />} />
            <Route exact path="/admissions" element={<Admissions />} />
            <Route exact path="/faq" element={<FAQ />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;