import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.scss";
import Banner from "../../assets/images/pva-banner.png";
import { arrowDown, arrowUp } from '../../assets/images/svg';
import Humanities1 from '../../assets/images/why-pva-1.png';
import Humanities2 from '../../assets/images/why-pva-2.png';
import Humanities3 from '../../assets/images/why-pva-3.png';
import Humanities4 from '../../assets/images/why-pva-4.png';
import Humanities5 from '../../assets/images/why-pva-5.png';

import PVAStudentTestimonial from '../../assets/images/pva_student_testimonial.jpeg';
import PVATeacherTestimonial from '../../assets/images/pva_teacher_testimonial.jpeg';
import PVAPartnerTestimonial from '../../assets/images/pva_partner_testimonial.jpg';

import SpecilisationBorder from '../../assets/images/specilisation-border.png';
import SpecilisationBorder5 from '../../assets/images/specilisation-border-5.png';
import CareerPathways from '../../assets/images/career_pathways_pva.png';

import GMILogo from '../../assets/images/GMI_logo.png';
import MAHELogo from '../../assets/images/MAHE_logo.png';
import WhistlingWoodsLogo from '../../assets/images/whistling_woods.png';

import Breadcrum from '../../common/Breadcrum';
import SchoolGallery from '../../common/Gallery';
import GalleryImg1 from '../../assets/images/pva-gallery-1.jpg';
import GalleryImg2 from '../../assets/images/pva-gallery-2.jpg';
import GalleryImg3 from '../../assets/images/pva-gallery-3.jpg';
import GalleryImg4 from '../../assets/images/pva-gallery-4.jpg';
import GalleryImg5 from '../../assets/images/pva-gallery-5.jpg';
import GalleryImg6 from '../../assets/images/pva-gallery-6.jpg';
import GalleryImg7 from '../../assets/images/pva-gallery-7.jpg';
import GalleryImg8 from '../../assets/images/pva-gallery-8.jpg';
import GalleryImg9 from '../../assets/images/pva-gallery-9.jpg';
import GalleryImg10 from '../../assets/images/pva-gallery-10.jpg';
import GalleryImg11 from '../../assets/images/pva-gallery-11.jpg';
import GalleryImg12 from '../../assets/images/pva-gallery-12.jpg';
import { LanguageContext } from '../../App';
import { lang } from '../../language';

const PVA = () => {
    const language= React.useContext(LanguageContext)

    const [showCurriculum, setShowCurriculum] = useState(false);
    const [showCreators, setShowCreators] = useState(false);

    return (
        <div className='specialization'>
            <Breadcrum name={"Performing and Visual Arts"} path="/specialisation/pva" />
            <div className='banner'>
                <img src={Banner} alt="DBSE SOSE" title='DBSE SOSE' />
                <div className="inner-breadcrum-container">
                    <div className="inner-breadcrum" dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.inner_breadcrum }}></div>
                    <div className="inner-breadcrum-hover" dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.inner_breadcrum_hover }}></div>
                </div>
            </div>
            <div className='specialization-description' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_description }}>
            </div>
            <div className='specialization-section'>
                <div className='specialization-content'>

                    <div className='why'>
                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.title }}></div>
                        <div className='why-card'>
                            <img src={Humanities1} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.card1 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities2} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.card2 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities3} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.card3 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities4} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.card4 }}></div>
                            </div>
                        </div>
                        <div className='why-card'>
                            <img src={Humanities5} alt="DBSE SOSE" className="why-icon" />
                            <div className='content'><img src={SpecilisationBorder5} alt="" />
                                <div dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.specialization_section.why_card.card5 }}></div>
                            </div>
                        </div>
                    </div>

                    <div className='accordian currriculum'>
                        <div className='outer' onClick={() => setShowCurriculum(!showCurriculum)}>
                        {showCurriculum ? (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.title }}></div>    
                            ): (
                                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].Humanities.CurriculumDesign.titleClosed }}></div>
                            )}
                            <div className='icon'>{showCurriculum ? arrowUp : arrowDown}</div>
                        </div>
                        {showCurriculum && (

                            <div className='inner'>
                                <div className='currriculum_design'>
                                    <div className='currriculum_grade_section'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_9_10 }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading left' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_heading_left }}></div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_heading right' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_heading_right }}></div>
                                            </div>
                                        </div>

                                        <div className='currriculum_subjects'>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description left'>
                                                    <ul>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.Mathematics }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.English }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.Hindi }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.Science }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.Individual_Societies }}></li>
                                                        <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.subjects.Entrepreneurial }}></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='currriculum_container'>
                                                <div className='currriculum_description right'>
                                                    <div className='paragraph'>
                                                        <div className='title'dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph1.title }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph1.sub_title1 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph1.desc1 }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph1.sub_title2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph1.desc2 }}></div>
                                                    </div>
                                                    <div style={{ textAlign: "center", color: "#1f4ca5", fontWeight: "600" }}> OR </div>
                                                    <div className='paragraph'>
                                                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph2.title }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph2.sub_title1 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph2.desc1 }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph2.sub_title2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph2.desc2 }}></div>
                                                    </div>
                                                    <div style={{ textAlign: "center", color: "#1f4ca5", fontWeight: "600" }}> OR </div>
                                                    <div className='paragraph'>
                                                        <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph3.title }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph3.sub_title1 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph3.desc1 }}></div>
                                                        <div className='sub-title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph3.sub_title2 }}></div>
                                                        <div className='desc' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.currriculum_description_right.paragraph3.desc2 }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='currriculum_grade_section section_change'>
                                        <div className='heading'>
                                            <div className='horizontal-border'></div>
                                            <div className='grade' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.title }}></div>
                                            <div className='horizontal-border'></div>
                                        </div>


                                        <div className='currriculum_container'>
                                            <div className='currriculum_heading all' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.currriculum_heading }}></div>
                                        </div>
                                        <div className='currriculum_container'>
                                            <div className='currriculum_description all'>
                                                <ul>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.subject.English }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.subject.Mathematics }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.subject.Business }}></li>
                                                    <li dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.CurriculumDesign.Grade_11_12.subject.specialisation }}></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className='horizontal'></div>
                    <div className='accordian'>
                        <div className='outer' onClick={() => setShowCreators(!showCreators)}>
                            <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Knowledge_Partners.title }}></div>
                            <div className='icon'>{showCreators ? arrowUp : arrowDown}</div>
                        </div>
                        {showCreators && (

                            <div className='inner'>

                                <div className='creators_container'>
                                    <div className='images_container pva'>
                                        <img src={GMILogo} alt="GMI Logo" />
                                        <img src={MAHELogo} alt="MAHE Logo" />
                                        <img src={WhistlingWoodsLogo} alt="Whistling Woods Logo" />
                                    </div>
                                    <div className='content_container'>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Knowledge_Partners.Global_Music_Institute }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Knowledge_Partners.Srishti_Manipal_Institute }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Knowledge_Partners.Whistling_Woods_Institute }}></p>
                                        <p dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Knowledge_Partners.para }}></p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='career_pathways'>
                <img src={CareerPathways} alt="CareerPathways" />
            </div>

            <div className='elegibility_criteria_section'>
                <div className='title' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.elegibility_criteria.title }}></div>

                <div className='elegibility_criteria_container'>
                    <div className='elegibility_criteria_container2'>
                        <p dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.elegibility_criteria.text }}></p>
                        {/* <button onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")} dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.elegibility_criteria.ApplyNow }}></button> */}
                    </div>
                    <div className='border'></div>
                </div>

                <div className='map-container'>
                    <iframe
                        src="https://www.google.com/maps/d/embed?mid=12y_qj02yIAxpkcfZPwxwLQc2kSJFxCA&ehbc=2E312F"
                        width="100%"
                        height="100%"
                        title='List Of ASoSE PVA Schools'
                    />
                </div>

                <div className='title testinomials_heading'></div>
            </div>

            <div className='testimonials_section'>
                <Carousel
                    showArrows={true}
                    autoPlay
                    emulateTouch
                    swipeable
                    stopOnHover
                    infiniteLoop
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                >
                    <div className='testimonials_container'>
                        <div className='image'><img src={PVAStudentTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container1.para }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container1.name }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container1.post }}></div>

                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={PVATeacherTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container2.para }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container2.name }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container2.post }}></div>
                        </div>
                    </div>
                    <div className='testimonials_container'>
                        <div className='image'><img src={PVAPartnerTestimonial} alt="" /></div>
                        <div className='desc'>
                            <div className='para' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container3.para }}></div>
                            <div className='name' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container3.name }}></div>
                            <div className='post' dangerouslySetInnerHTML={{ __html: lang[language].pva.heading.Testimonials.container3.post }}></div>
                        </div>
                    </div>
                </Carousel>

            </div>
            <SchoolGallery images={[
                { src: GalleryImg8, width: 250, height: 152 },
                { src: GalleryImg12, width: 250, height: 152 },
                { src: GalleryImg1, width: 250, height: 152 },
                { src: GalleryImg2, width: 250, height: 152 },
                { src: GalleryImg3, width: 250, height: 152 },
                { src: GalleryImg4, width: 250, height: 152 },
                { src: GalleryImg5, width: 250, height: 152 },
                { src: GalleryImg6, width: 250, height: 152 },
                { src: GalleryImg7, width: 250, height: 152 },
                { src: GalleryImg9, width: 250, height: 152 },
                { src: GalleryImg10, width: 250, height: 152 },
                { src: GalleryImg11, width: 250, height: 152 }
            ]} />
        </div>
    )
}

export default PVA;