import React, { useEffect, useState } from 'react';
import Logo from "../assets/images/sose_logo.png";
import DbseLogo from "../assets/images/DBSE_Logo.png";
import "./style.scss";
import { useLocation, useNavigate } from 'react-router';
import { hamburgerIcon } from '../assets/images/svg';

const Header = ({ setLanguage }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [openHanburger, setOpenHanburger] = useState(false);
    const [clock, setClock] = useState(false);

    const retDate = () => {
        const today = new Date();
        let date = today.getDate();
        let month = today.toLocaleString("default", { month: "short" });
        let year = today.getFullYear();
        let time = today.toLocaleString("default", {
            hour: "numeric",
            minute: "numeric"
        });
        setClock(`${date} ${month} ${year} | ${time} IST`);
        let sLeft = (60 - today.getSeconds()) * 1000;
        clock ? setTimeout(retDate, 60000) : setTimeout(retDate, sLeft);
    };

    useEffect(() => {
        retDate();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [location])

    return (
        <div>
            <div className='topHeader'>
                <div className='topHeaderContainer'>
                    <div className="time">{clock}</div>
                    <div className="language">
                        <span onClick={() => navigate("/")} >Home</span>
                        {/* 
                            <span onClick={() => navigate("/")} >A-</span>
                            <span onClick={() => navigate("/")} >A</span>
                            <span onClick={() => navigate("/")} >A+</span>
                            <span onClick={() => setLanguage("hn")} >हिन्दी</span>
                            <span onClick={() => setLanguage("en")} >English</span>
                        */}
                    </div>
                </div>
            </div>
            <div className='mainHeader'>
                <div className='headerContainer'>
                    <div className='logos'>
                        <img src={DbseLogo} alt="DBSE" />
                        <img src={Logo} alt="SOSE" />
                    </div>
                    <div className='links'>
                        <div className='links-top'>
                            <button className='login' onClick={() => window.open("https://www.edudel.nic.in//sose/admission/Home.aspx")}>Login</button>
                            <div className='hamburger' onClick={() => setOpenHanburger(!openHanburger)}>{hamburgerIcon}</div>
                        </div>

                        <div className='links-bottom'>
                            <ul>
                                <li className={location.pathname === "/" ? 'active' : ""}><span onClick={() => navigate("/")}>Home</span></li>
                                <li className={location.pathname === "/about/sose" ? 'active' : ""}><span onClick={() => navigate("/about/sose")}>About ASOSE</span></li>
                                <li className={location.pathname === "/about/dbse" ? 'active' : ""}><span onClick={() => navigate("/about/dbse")}>About DBSE</span></li>
                                <li className={`specialization_tab ${location.pathname.includes("/specialisation") ? 'active' : ""}`}>
                                    {/*eslint-disable-next-line*/}
                                    <span>Domains Of Specialisation</span>
                                    <div className="sub-section">
                                        <div className="sub-container">
                                            <span onClick={() => navigate("/specialisation/afps")}>Armed Forces Preparatory School</span>
                                            <span onClick={() => navigate("/specialisation/he21")}>High-End 21st Century Skills</span>
                                            <span onClick={() => navigate("/specialisation/humanities")}>Humanities</span>
                                            <span onClick={() => navigate("/specialisation/pva")}>Performing and Visual Arts</span>
                                            <span onClick={() => navigate("/specialisation/stem")}>STEM</span>
                                        </div>
                                    </div>
                                </li>
                                <li className={location.pathname === "/admissions" ? 'active' : ""}><span onClick={() => navigate("/admissions")}>Admissions</span></li>
                                <li className={location.pathname === "/faq" ? 'active' : ""}><span onClick={() => navigate("/faq")}>FAQs</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {openHanburger && (
                <div className='links-bottom-mobile'>
                    <ul>
                        <li className={location.pathname === "/" ? 'active' : ""}><span onClick={() => navigate("/")}>Home</span></li>
                        <li className={location.pathname === "/about/sose" ? 'active' : ""}><span onClick={() => navigate("/about/sose")}>About ASOSE</span></li>
                        <li className={location.pathname === "/about/dbse" ? 'active' : ""}><span onClick={() => navigate("/about/dbse")}>About DBSE</span></li>
                        <li className={`specialization_tab ${location.pathname.includes("/specialisation") ? 'active' : ""}`}>
                            {/*eslint-disable-next-line*/}
                            <span>Domains Of Specialisation</span>
                            <div className="sub-section">
                                <div className="sub-container">
                                    <span onClick={() => navigate("/specialisation/afps")}>Armed Forces Preparatory School</span>
                                    <span onClick={() => navigate("/specialisation/he21")}>High-End 21st Century Skills</span>
                                    <span onClick={() => navigate("/specialisation/humanities")}>Humanities</span>
                                    <span onClick={() => navigate("/specialisation/pva")}>Performing and Visual Arts</span>
                                    <span onClick={() => navigate("/specialisation/stem")}>STEM</span>
                                </div>
                            </div>
                        </li>
                        <li className={location.pathname === "/admissions" ? 'active' : ""}><span onClick={() => navigate("/admissions")}>Admissions</span></li>
                        <li className={location.pathname === "/faq" ? 'active' : ""}><span onClick={() => navigate("/faq")}>FAQs</span></li>
                    </ul>
                </div>
            )}


        </div>
    )
};

export default Header;