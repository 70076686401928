import React from 'react'
// import { Link } from 'react-router-dom';
import Logo from "../assets/images/sose_logo.png";
import DbseLogo from "../assets/images/DBSE_Logo.png";
import DilliSarkarLogo from "../assets/images/Dilli_Sarkar_Logo.png";
import { useNavigate } from 'react-router-dom';

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="">
          <div className="copyright">&copy; {new Date().getFullYear()}-{new Date().getFullYear() + 1} Dr. B.R. Ambedkar Schools of Specialised Excellence</div>
        </div>
        <div className="footer-branding">
          <span onClick={() => navigate("/")}><img src={DbseLogo} alt="DBSE" /></span>
          <span onClick={() => navigate("/")}><img className="sse-logo" src={DilliSarkarLogo} alt="Dilli Sarker" /></span>
          <span onClick={() => navigate("/")}><img className="sse-logo" src={Logo} alt="SOSE" /></span>
        </div>
      </div>
    </footer>
  )
}
