import React, { useState } from 'react';
import "./AboutASoSE.scss";
import Gp1 from "../assets/images/Gp1.png";
import Gp2 from "../assets/images/Gp2.png";
import Gp3 from "../assets/images/Gp3.png";
import ScienceLogo from "../assets/images/ScienceLogo.png";
import HumanitiesLogo from "../assets/images/HumanitiesLogo.png";
import HighEndLogo from "../assets/images/HighEndLogo.png";
import PVALogo from "../assets/images/PVALogo.png";
import AFPSLogo from "../assets/images/AFPSLogo.png";
import DBSE_Logo from "../assets/images/DBSE_Logo.png";
import ASOSEHeader from "../assets/images/ASOSEHeader.png";
import Breadcrum from '../common/Breadcrum';
import { lang } from '../language';
import { LanguageContext } from '../App';
import { locations } from '../locations';
import { arrowDown, arrowUp } from '../assets/images/svg';

const AboutASoSE = () => {
    const language = React.useContext(LanguageContext)
    return (
        <div className='AboustASose_container'>
            <Breadcrum name={"About ASOSE"} path="/about/sose" />
            <div className='asoseheaderpic'>
                <div className='ASOSEHeader'><img src={ASOSEHeader} alt="aboutAsose" /></div>
                <div className='breadcrum' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.heading }}></div>

            </div>
            <div className='AboutASoSE' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.intro }}>

            </div>
            <div className='visionmission-container'>
                <div className='vision' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.vision.vision }}>

                </div>

                <div className='mission' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.vision.mission }}>
                </div>
                {/* <ul className='datalist'>
                    <li>World-class infrastructure and top teachers to transact new-age curriculum, and assessment in the specialised domains.</li>
                    <li>Partnership with reputed organizations, premier universities and globally & locally acclaimed professionals.</li>
                    <li>Increased exposure to various career pathways through master classes, expert interactions, field visits,etc. </li>
                </ul> */}
            </div>
            <div className='Infrastructure-partnership'>
                <div className='aboutasose class_1'>
                    <div className='infra-desc' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.Infrastructure.infra1 }}>
                    </div>
                    <div className='icon-container'><img src={Gp1} alt="aboutAsose" /></div>
                </div>
                <div className='aboutasose class_2'>
                    <div dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.Infrastructure.infra2 }}></div>
                    <div className='icon-container'><img src={Gp2} alt="aboutAsose" /></div>
                </div>
                <div className='aboutasose class_3' >
                    <div dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.Infrastructure.infra3 }}></div>
                    <div className='icon-container'><img src={Gp3} alt="aboutAsose" /></div>
                </div>
            </div>
            <div className='ASoSEStoryHding' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.ASoSEStoryHding.heading }}>
            </div>
            <div className='ASoSEStoryfirst' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.ASoSEStoryHding.ASoSEStoryfirst }}>
            </div>
            <div className='ASoSEStorysecond' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.ASoSEStoryHding.ASoSEStorysecond }}>
            </div>
            <div className='extra-curricular-section'>
                <div className='extra-curricular'>
                    <div className='stats-container'>
                        <div className='stats'>
                            <div className='number'>79%</div>
                            <div className='subhead'>of students in grades 8-10<br />&</div>
                            <div className='number'>88%</div>
                            <div className='subhead'>of students of grades 11-12</div>
                            <div className='desc'>expressed an interest in the idea of <i>early specialisation</i> in their subjects of interest.</div>
                        </div>
                    </div>
                    <div className='verticle'></div>
                    <div className='stats-container'>
                        <div className='stats'>
                            <div className='number'>75%</div>
                            <div className='subhead'>parents of students across grades 8-12</div>
                            <div className='desc'>believed early specialisation would be beneficial for their child.</div>
                        </div>
                    </div>
                    <div className='horizontal'></div>
                    <div></div>
                    <div className='horizontal'></div>

                    <div className='stats-container'>
                        <div className='stats'>
                            <div className='number'>73%</div>
                            <div className='subhead'>students from grades 8-10<br />&</div>
                            <div className='number'>70%</div>
                            <div className='subhead'>students from grades 11-12</div>
                            <div className='desc'>expressed interest in pursuing their extra-curricular interest as formal study.</div>
                        </div>
                    </div>
                    <div className='verticle'></div>
                    <div className='stats-container'>
                        <div className='stats'>
                            <div className='number'>71%</div>
                            <div className='subhead'>parents of students from grades 8-10<br />&</div>
                            <div className='number'>66%</div>
                            <div className='subhead'>parents of students from grades 11-12</div>
                            <div className='desc'>indicated that they would support their children in pursuing extra-curricular interests through formal study or as a career.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='subjects-container'>
                <div className='sub-desc' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.subDesc }}>

                </div>
                <div className='sub-logos'>
                    <div className='logo-container'><img src={AFPSLogo} alt="Science" /></div>
                    <div className='Subjects curved-top'>Armed Forces Prepatory School</div>
                    <div className='Subjects-details'>AFPS will prepare students for
                        entry to Indian defence service examinations
                        such as for the Army, Navy, and Airforce.</div>
                </div>
                <div className='sub-logos'>
                    <div className='logo-container'><img src={HighEndLogo} alt="Science" /></div>
                    <div className='Subjects' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.logo3.subject }}></div>
                    <div className='Subjects-details' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.logo3.subjectdetails }}></div>
                </div>
                <div className='sub-logos'>
                    <div className='logo-container'><img src={HumanitiesLogo} alt="Science" /></div>
                    <div className='Subjects'>Humanities</div>
                    <div className='Subjects-details'>Building critical thinking and
                        research mindset in students, to help them become leading
                        social scientists, lawyers, journalists, development professionals and more.</div>
                </div>
                <div className='sub-logos'>
                    <div className='logo-container'><img src={PVALogo} alt="Science" /></div>
                    <div className='Subjects' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.logo4.subject }}></div>
                    <div className='Subjects-details' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.logo4.subjectdetails }}></div>
                </div>
                <div className='sub-logos'>
                    <div className='logo-container'><img src={ScienceLogo} alt="Science" /></div>
                    <div className='Subjects curved-bottom'>Science, Technology, Engineering and Mathematics (STEM)</div>
                    <div className='Subjects-details'>Rigorous STEM-based education from the leading minds in the industry for preparing students for higher education examinations like JEE & NEET</div>
                </div>
            </div>
            <div className='Map' >
                <div className='locations'>
                    <div className='locations_section'>
                        <div className='locations-section-heading'>Dr. B.R. Ambedkar School Of Specialised Excellence Schools Locations</div>
                        <LocationsTable head="Armed Forces Prepatory School (AFPS)" locations={locations.filter((item) => item.Type === "AFPS")} />
                        <LocationsTable head="High-end 21st Century Skills (HE21)" locations={locations.filter((item) => item.Type === "HE21")} />
                        <LocationsTable head="Humanities" locations={locations.filter((item) => item.Type === "Humanities")} />
                        <LocationsTable head="Performing and Visual Arts (PVA)" locations={locations.filter((item) => item.Type === "PVA")} />
                        <LocationsTable head="Science, Technology, Engineering and Mathematics (STEM)" locations={locations.filter((item) => item.Type === "STEM")} />
                    </div>
                </div>
            </div>



            <div className='MapPic'>
                <iframe
                    src="https://www.google.com/maps/d/embed?mid=1FwUsQMJjxiXVuNK0wtWAP_L2gdfXYxs&ehbc=2E312F"
                    width="100%"
                    height="100%"
                    title='List Of ASoSE Schools'
                />
            </div>

            <div className='aff' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.Affiliation.heading }}></div>
            <div className='Aff-container'>
                <div className='aff-desc' dangerouslySetInnerHTML={{ __html: lang[language].aboutASOSE.Affiliation.define }}>
                </div>
                <div className='DbseLogo'><img src={DBSE_Logo} alt="about" /></div>
            </div>
        </div>
    )
}
export default AboutASoSE;

const LocationsTable = ({ head, locations }) => {

    const [open, setOpen] = useState(false);

    return (

        <div className='locations-table'>
            <div className='locations-row' onClick={() => setOpen(!open)}>
                <div className='dropdown-heading'>{head}</div>
                <div>{open ? arrowUp : arrowDown}</div>
            </div>
            {open && (
                <table className='fl-table'>
                    <thead>
                        <tr>
                            <th>Specialisation</th>
                            <th>School Name</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((item) => (
                            <tr>
                                <td>{item.Type}</td>
                                <td>{item.SchoolName}</td>
                                <td><a href={item.Location} target="_blank" rel='noreferrer'>View</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>

    )
}